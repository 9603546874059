import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {ENTITY_ID_DEVICES} from "../generic/Constants";
import {showToast, TOAST_SUCCESS} from "../../utils/toast/Toast";
import {defaultDevice} from "../generic/InitialStates";
import {apiGenericCreate, apiGenericUpdate, genericGetOne} from "../generic/crud";
import {AGCText} from "../../generic-components/AGCText";
import * as Yup from 'yup';


export function AddDevicesDialog(props) {
    const {show, handleClose, id} = props;
    const [forUpdate, setForUpdate] = useState(false);
    const [objectForSubmit, setObjectForSubmit] = useState(defaultDevice);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("Device");
    const entityName = "Device";

    const validationSchema = Yup.object({
        name: Yup.string().required('Device Name is required'),
    });

    const formik = useFormik({
        initialValues: objectForSubmit, enableReinitialize: true, onSubmit(device, {resetForm}) {
            setIsLoading(true);
            if (!id) {
                apiGenericCreate(ENTITY_ID_DEVICES, device).then(() => {
                    setIsLoading(false);
                    handleClose();
                    showToast(entityName + " is Created!", TOAST_SUCCESS);
                    resetForm();
                }).catch(() => {
                    setIsLoading(false);
                });
            } else {
                apiGenericUpdate(ENTITY_ID_DEVICES, device, id).then(() => {
                    setIsLoading(false);
                    handleClose();
                    showToast(entityName + " is Updated!", TOAST_SUCCESS);
                    resetForm();
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        }, validationSchema
    });

    useEffect(() => {
        if (id != null) {
            setForUpdate(true);
            setTitle("Edit " + entityName);
            setIsLoading(false);

            genericGetOne(ENTITY_ID_DEVICES, id).then(({data}) => {
                setObjectForSubmit(data);
                setTitle("Update " + entityName);
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setForUpdate(false);
            setObjectForSubmit(defaultDevice);
            setTitle("New " + entityName);
        }
    }, [show]);


    return (<Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay">
            {!isLoading && (<Form className="form form-label-right">
                <div className="form-group row">

                    <Form.Group className="col-lg-12">
                        <AGCText formik={formik}
                                 name={"Device Name"}
                                 placeHolder={'Enter device name'}
                                 id={"name"}
                                 required={true}
                                 toolTip={"Please enter the device name"}
                                 value={formik.values.name}/>
                    </Form.Group>
                </div>

                <br/>
                <Modal.Footer>
                    <div>
                        <button
                            type="button"
                            className="btn btn-light btn-elevate"
                            onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={formik.handleSubmit}
                            className="btn btn-primary btn-elevate ms-4">
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Form>)}
            {isLoading && <span>Please Wait, Fetching Information from Server</span>}
        </Modal.Body>
    </Modal>);
}
