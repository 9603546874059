import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const LogAndRedirect = ({ to }) => {
    const location = useLocation();

    // Log the current path
    console.log("Redirecting from:", location.pathname);

    // Render the Navigate component to redirect
    return <Navigate to={to} />;
};

export default LogAndRedirect;