import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {UsersPage} from "../pages/user/UsersPage";
import {AuditsPage} from "../pages/audit-history/AuditsPage";
import {useAuth} from "../modules/auth";
import {isSuperAdmin} from "../utils/Utils";
import OAuth2RedirectHandler from "../modules/auth/oauth2/OAuth2RedirectHandler";
import LogAndRedirect from "../pages/LogAndRedirect";
import {ProjectsPage} from "../pages/projects/ProjectsPage";
import React, {useEffect} from 'react';
import {ManualTestCasesListing} from "../pages/test-cases/manual/ManualTestCasesListing";
import {NewManualTestCase} from "../pages/test-cases/manual/NewManualTestCase";
import {TestRunsListing} from "../pages/test-runs/TestRunsListing";
import {NewManualTestRun} from "../pages/test-runs/manual/NewManualTestRun";
import {TagsPage} from "../pages/tags/TagsPage";
import {DevicesPage} from "../pages/devices/DevicesPage";
import {APITestCasesListing} from "../pages/test-cases/api/APITestCasesListing";
import {NewApiTestPage} from "../pages/test-cases/api/NewApiTestPage";

const PrivateRoutes = () => {
    const {currentUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if selectedProject is null and projects array is empty or null
        if (currentUser.selectedProject === null || !currentUser.projects || currentUser.projects.length === 0) {
            navigate('/projects');
        }
    }, [currentUser, navigate]);

    return (<Routes>
        <Route element={<MasterLayout/>}>

            {/* Redirect to Dashboard after success login/registartion */}

            {isSuperAdmin(currentUser) === false && <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>}

            {isSuperAdmin(currentUser) && <Route path='auth/*' element={<Navigate to='/sadashboard'/>}/>}


            {/* Dashboard */}
            <Route path='dashboard' element={<DashboardWrapper/>}/>
            <Route path='projects' element={<ProjectsPage/>}/>

            {/*Manual Test Cases*/}
            <Route path='manual-test-cases' element={<ManualTestCasesListing type={"manual"}/>}/>
            <Route path="edit-manual-test-cases/:id" element={<NewManualTestCase/>}/>
            <Route path="add-manual-test-cases" element={<NewManualTestCase/>}/>

            {/*Manual Test Runs*/}
            <Route path='manual-test-runs' element={<TestRunsListing type={"manual"}/>}/>
            <Route path="edit-manual-test-runs/:id" element={<NewManualTestRun/>}/>
            <Route path="add-manual-test-runs" element={<NewManualTestRun/>}/>

            {/*API Test Cases*/}
            <Route path='api-test-cases' element={<APITestCasesListing type={"manual"}/>}/>
            <Route path="edit-api-test-cases/:id" element={<NewApiTestPage/>}/>
            <Route path="add-api-test-cases" element={<NewApiTestPage/>}/>

            <Route path='users' element={<UsersPage/>}/>
            <Route path='tags' element={<TagsPage/>}/>
            <Route path='devices' element={<DevicesPage/>}/>
            <Route path='auditLogs' element={<AuditsPage/>}/>

            {/*Super Admin*/}
            <Route path='sadashboard' element={<DashboardWrapper/>}/>

            <Route path='/oauth2/redirect' element={<OAuth2RedirectHandler/>}/>

            <Route path='*' element={<LogAndRedirect to='/error/404'/>}/>
        </Route>
    </Routes>)
};

export {PrivateRoutes}
