import React, {FC, useEffect, useRef, useState} from 'react'
import {SearchComponent} from '../../../assets/ts/components'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {genericGetAll} from "../../../../app/pages/generic/crud";
import {useNavigate} from "react-router-dom";
import {getStudentImage} from "../../../../app/utils/Utils";
import {ENTITY_ID_PROJECT} from "../../../../app/pages/generic/Constants";

const Search: FC = () => {
    const navigate = useNavigate();
    const searchDialog = useRef<HTMLDivElement | null>(null);
    const element = useRef<HTMLDivElement | null>(null);
    const wrapperElement = useRef<HTMLDivElement | null>(null);
    const resultsElement = useRef<HTMLDivElement | null>(null);
    const suggestionsElement = useRef<HTMLDivElement | null>(null);
    const emptyElement = useRef<HTMLDivElement | null>(null);

    const [dataList, setDataList] = useState({
        totalCount: 0,
        entities: [],
    });

    interface SearchResult {
        id: string;
        firstName: string;
        lastName: string;
        className: string;
    }

    const processs = (search: SearchComponent) => {
        let tableFilterData = {
            page: 1,
            sizePerPage: 10,
            currentIndex: 0,
            sortBy: "firstName",
            searchString: search.getQuery(),
            searchField: "firstName,lastName,registrationNumber"
        };

        // Hide recently viewed
        suggestionsElement.current!.classList.add('d-none');

        genericGetAll(ENTITY_ID_PROJECT, tableFilterData).then(
            ({data}) => {
                search.complete();

                setDataList(data);
                if (data.totalCount === 0) {
                    // Hide results
                    resultsElement.current!.classList.add('d-none');
                    // Show empty message
                    emptyElement.current!.classList.remove('d-none')
                } else {
                    // Show results
                    resultsElement.current!.classList.remove('d-none');
                    // Hide empty message
                    emptyElement.current!.classList.add('d-none')
                }
            })
            .catch(() => {
                // Complete search
                search.complete()
            });
    };

    const clear = (search: SearchComponent) => {
        // Show recently viewed
        suggestionsElement.current!.classList.remove('d-none');
        // Hide results
        resultsElement.current!.classList.add('d-none');
        // Hide empty message
        emptyElement.current!.classList.add('d-none');
    };

    function studentClicked(studentId: string) {
        navigate(`/studentProfile?studentId=${studentId}`);
    }

    useEffect(() => {

        // Initialize search handler
        const searchObject = SearchComponent.createInsance('#kt_header_search');

        // Search handler
        searchObject!.on('kt.search.process', processs);

        // Clear handler
        searchObject!.on('kt.search.clear', clear)

    }, []);

    const searchResults = dataList.entities.map((searchResult: SearchResult) => {
            return (
                <a className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                   key={searchResult.id} onClick={() => studentClicked(searchResult.id)}>
                    <div className='symbol symbol-40px me-4'>
                        <img src={toAbsoluteUrl(getStudentImage(searchResult))} alt=''/>
                    </div>

                    <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'>{searchResult.firstName} {searchResult.lastName}</span>
                        <span className='fs-7 fw-bold text-muted'>{searchResult.className}</span>
                    </div>
                </a>
            )
        }
    );


    return (
        <>
            <div
                id='kt_header_search'
                className='d-flex align-items-stretch'
                data-kt-search-keypress='true'
                data-kt-search-min-length='2'
                data-kt-search-layout='menu'
                data-kt-menu-trigger='auto'
                data-kt-menu-overflow='false'
                data-kt-menu-permanent='true'
                data-kt-menu-placement='bottom-end'
                ref={element}
            >
                <div
                    className='d-flex align-items-center'
                    data-kt-search-element='toggle'
                    id='kt_header_search_toggle'>
                    <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
                        <KTIcon iconName='magnifier' className='fs-1'/>
                    </div>
                </div>


                <div
                    data-kt-search-element='content'
                    ref={searchDialog}
                    className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'>
                    <div
                        className={'main'}
                        ref={wrapperElement}
                        data-kt-search-element='wrapper'>
                        <form
                            data-kt-search-element='form'
                            className='w-100 position-relative mb-3'
                            autoComplete='off'>
                            <KTIcon
                                iconName='magnifier'
                                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-0'
                            />

                            <input
                                type='text'
                                className='form-control form-control-flush ps-10'
                                name='search'
                                placeholder='Search...'
                                data-kt-search-element='input'
                            />

                            <span
                                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                                data-kt-search-element='spinner'>
                                  <span className='spinner-border h-15px w-15px align-middle text-gray-400'/></span>

                            <span
                                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                                data-kt-search-element='clear'>
                                    <KTIcon iconName='cross' className='fs-2 text-lg-1 me-0'/>
                            </span>


                        </form>

                        <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
                            <div className='scroll-y mh-200px mh-lg-350px'>
                                <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                                    Students
                                </h3>

                                {searchResults}

                            </div>
                        </div>

                        <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
                            <div className='d-flex flex-stack mb-4'>
                                <span className='text-muted fs-7 me-2'>Search for Test cases</span>
                            </div>
                        </div>

                        <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
                            <div className='pt-10 pb-10'>
                                <KTIcon iconName='search-list' className='fs-4x opacity-50'/>
                            </div>

                            <div className='pb-15 fw-bold'>
                                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                                <div className='text-muted fs-7'>Please try again with a different query</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};

export {Search}
