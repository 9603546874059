import {PageTitle} from "../../../_metronic/layout/core";
import React, {useContext, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import OreoContext from "../../OreoContext";
import {AddEditProjectDialog} from "./AddEditProjectDialog";
import {activateProject, genericDelete, genericGetAll} from "../generic/crud";
import {ENTITY_ID_MANUAL_TEST_CASE, ENTITY_ID_PROJECT} from "../generic/Constants";
import {defaultList} from "../generic/InitialStates";
import {useNavigate} from "react-router-dom";
import {ProjectsEmptyPage} from "./ProjectsEmptyPage";
import {getAuth, useAuth} from "../../modules/auth";
import {getUserByToken} from "../../modules/auth/core/_requests";
import {showToast, TOAST_SUCCESS} from "../../utils/toast/Toast";
import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import {showConfirmDialog} from "../../generic-components/ConfirmDialog";
import {InviteUserDialog} from "./InviteUserDialog";
import {InvitesListComponent} from "./InvitesListComponent";

export function ProjectsPage() {
    const myData = useContext(OreoContext);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModelId, setShowModelId] = useState(null);
    const [reload, setReload] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(1000);
    const [page, setPage] = useState(1);
    const [pageLoadComplete, setPageLoadComplete] = useState(false);
    const [dataList, setDataList] = useState(defaultList);
    const navigate = useNavigate();
    const {currentUser} = useAuth();
    const {setCurrentUser} = useAuth();

    const profileBreadCrumbs = [{
        title: 'Home', path: '/dashboard', isSeparator: false, isActive: false,
    }, {
        title: '', path: '', isSeparator: true, isActive: false,
    },];

    useEffect(() => {
        myData.setBaseData({
            toolBarButtons: [{
                name: "ADD NEW PROJECT", color: "primary", icon: "fa fa-plus", onClick: addButtonClicked,
            },],
        });

        pullTheDataForPage();
    }, [reload]);

    function pullTheDataForPage() {
        let tableFilterData = {};

        setIsLoading(true);
        tableFilterData.page = page;
        tableFilterData.sizePerPage = sizePerPage;
        tableFilterData.currentIndex = 0;

        genericGetAll(ENTITY_ID_PROJECT, tableFilterData).then(({data}) => {
            setPageLoadComplete(true);

            setDataList(data);
            setIsLoading(false);
            setPage(1);
        })
        .catch(() => {
            setIsLoading(false);
            setPageLoadComplete(true);
        });
    }

    function reloadData() {
        setReload(reload + 1);
    }

    function inviteUser(projectId) {
        setShowModelId(projectId);
        setShowModal1(false);
        setShowModal2(true);
    }

    function addButtonClicked() {
        setShowModelId(null);
        setShowModal1(true);
        setShowModal2(false);
    }

    const handleClose = () => {
        setShowModal1(false);
        setShowModal2(false);
        reloadData();
    };

    const ProjectsList = () => (<div className="">
        <div className="row">
            {pageLoadComplete && projectMarkup}
        </div>
    </div>);

    function projectSelected(projectId) {
        activateProject(projectId).then(({data}) => {

            const auth = getAuth()
            if (auth && auth.accessToken) {
                getUserByToken(auth.accessToken).then(({data}) => {
                    setCurrentUser(data);
                    showToast("Project is Activated, All your Activities will be logged in the activated Project!", TOAST_SUCCESS);

                    reloadData();
                }).catch(() => {
                    reloadData();
                });
            }

            reloadData();
        }).catch(() => {
            reloadData();
        });
    }

    function editProject(id) {
        setShowModelId(id);
        setShowModal1(true)
    }



    const projectMarkup = dataList.entities.map((project) => {
        const isActiveProject = currentUser.selectedProject && currentUser.selectedProject.id === project.id;

        return (<div className="col-md-6 col-lg-4 col-xl-3 mb-5" key={project.id}>
            <div className="card h-100 shadow">
                <Card>
                    <div className="position-relative">
                        <Card.Img variant="top" src={`/media/stock/600x600/img-${project.imageNumber}.jpg`}/>
                        {isActiveProject && (<div className="position-absolute top-0 end-0 m-4">
                            <span className="badge badge-light-success">ACTIVE</span>
                        </div>)}
                    </div>
                    <Card.Body>
                        <Card.Title>{project.name}</Card.Title>
                        <Card.Text >
                            {project.description}
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <div className="">
                            {!isActiveProject && (

                                <a onClick={() => projectSelected(project.id)}
                                   className="btn btn-sm btn-light me-2 btn-active-color-primary">
                                    <i className="ki-duotone ki-check fs-3 d-none"/>
                                    <span className="indicator-label">Activate</span>
                                </a>)}

                            <Menu menuButton={<MenuButton
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                                <i className="ki-solid ki-dots-horizontal fs-2x"/>{" "}
                            </MenuButton>} transition menuClassName='my-menu'>

                                <MenuItem className="flex my-menuitem px-3" onClick={() => editProject(project.id)}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Edit</span>
                                        <i className="fa fa-pen text-primary"/>
                                    </span>
                                </MenuItem>

                                <MenuItem className="flex my-menuitem px-3" onClick={()=> inviteUser(project.id)}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Invite</span>
                                        <i className="fa fa-share text-primary"/>
                                    </span>
                                </MenuItem>

                                <div className='separator text-muted py-0'></div>

                                <MenuItem className="flex my-menuitem px-3">
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Delete</span>
                                        <i className="fa fa-trash text-danger"/>
                                    </span>
                                </MenuItem>

                            </Menu>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </div>);
    });


    return <>
        {showModal1 && <AddEditProjectDialog show={showModal1} handleClose={handleClose} id={showModelId}/>}
        {showModal2 && <InviteUserDialog show={showModal2} handleClose={handleClose} projectId={showModelId}/>}
        <InvitesListComponent reloadData={reloadData} reload={reload}/>

        <ProjectsEmptyPage showEmpty={dataList.entities.length === 0} addButtonClicked={addButtonClicked}/>


        <PageTitle breadcrumbs={profileBreadCrumbs}>Projects </PageTitle>

        {dataList.entities.length !== 0 && <div className="card">
            <div className="row g-6 g-xl-9 mb-6 mb-xl-9 p-8">
                <ProjectsList/>
            </div>
        </div>}

    </>
}