import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {ENTITY_ID_PROJECT} from "../generic/Constants";
import {showToast, TOAST_SUCCESS} from "../../utils/toast/Toast";
import {defaultProject} from "../generic/InitialStates";
import {apiGenericCreate, apiGenericUpdate, genericGetOne} from "../generic/crud";
import {AGCText} from "../../generic-components/AGCText";
import {AGCTextArea} from "../../generic-components/AGCTextArea";
import * as Yup from 'yup';


export function AddEditProjectDialog(props) {
    const {show, handleClose, id} = props;
    const [forUpdate, setForUpdate] = useState(false);
    const [objectForSubmit, setObjectForSubmit] = useState(defaultProject);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("Project");
    const entityName = "Project";

    const validationSchema = Yup.object({
        name: Yup.string().required('Project Name is required'),
        description: Yup.string().required('Project Description is required'),
    });

    const formik = useFormik({
        initialValues: objectForSubmit, enableReinitialize: true, onSubmit(project, {resetForm}) {
            setIsLoading(true);
            if (!id) {
                apiGenericCreate(ENTITY_ID_PROJECT, project).then(() => {
                    setIsLoading(false);
                    handleClose();
                    showToast(entityName + " is Created!", TOAST_SUCCESS);
                    resetForm();
                }).catch(() => {
                    setIsLoading(false);
                });
            } else {
                apiGenericUpdate(ENTITY_ID_PROJECT, project, id).then(() => {
                    setIsLoading(false);
                    handleClose();
                    showToast(entityName + " is Updated!", TOAST_SUCCESS);
                    resetForm();
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        },
        validationSchema
    });

    useEffect(() => {
        if (id != null) {
            setForUpdate(true);
            setTitle("Edit " + entityName);
            setIsLoading(false);

            genericGetOne(ENTITY_ID_PROJECT, id).then(({data}) => {
                setObjectForSubmit(data);
                setTitle("Update " + entityName);
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setForUpdate(false);
            setObjectForSubmit(defaultProject);
            setTitle("New " + entityName);
        }
    }, [show]);


    return (<Modal
            size="lg"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay">
                {!isLoading && (<Form className="form form-label-right">
                        <div className="form-group row">

                            <Form.Group className="col-lg-12">
                                <AGCText formik={formik}
                                         name={"Project Name"}
                                         placeHolder={'Enter project name'}
                                         id={"name"}
                                         required={true}
                                         toolTip={"Please enter the project name"}
                                         value={formik.values.name}/>
                            </Form.Group>
                            <Form.Group className="col-lg-12 mt-10">
                                <AGCTextArea formik={formik}
                                             name={"Project Description"}
                                             placeHolder={'Describe your project in brief'}
                                             id={"description"}
                                             required={true}
                                             toolTip={"Please enter the project description"}
                                             value={formik.values.description}/>
                            </Form.Group>

                        </div>

                        <br/>
                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-light btn-elevate"
                                    onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate ms-4">
                                    Save
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>)}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>);
}
