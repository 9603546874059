import React from "react";
import {Form} from "react-bootstrap";

export function AGCPassword({formik, name, toolTip, id, placeHolder, value, forUpdate = false, required=true}) {
    return (
        <>
            <Form.Label className={required ? 'required' : ''}>{name}</Form.Label>
            <Form.Control
                id={id}
                type="password"
                placeholder={placeHolder}
                onChange={formik.handleChange}
                disabled={forUpdate}
                value={value}
            />
            <Form.Text className="text-muted">{toolTip}</Form.Text>
        </>
    );
}
