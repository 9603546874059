import React, {useContext, useEffect, useState} from "react";
import OreoContext from "../../OreoContext";
import {PageTitle} from "../../../_metronic/layout/core";
import {GenericTable} from "../generic/table/GenericTable";
import {defaultList} from "../generic/InitialStates";
import {ActionsColumnFormatter} from "../../column-formatters/ActionsColumnFormatter";
import {ENTITY_ID_DEVICES} from "../generic/Constants";
import {HeaderColumnFormatter} from "../../column-formatters/HeaderColumnFormatter";
import {DMSDateColumnFormatter} from "../../column-formatters/DMSDateColumnFormatter";
import {showConfirmDialog} from "../../generic-components/ConfirmDialog";
import {genericDelete} from "../generic/crud";
import {showToast, TOAST_SUCCESS} from "../../utils/toast/Toast";
import {AddDevicesDialog} from "./AddDevicesDialog";

export function DevicesPage() {
    const myData = useContext(OreoContext);
    const [reload, setReload] = useState(1);
    const [pageLoadComplete, setPageLoadComplete] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModelId, setShowModelId] = useState(null);

    const profileBreadCrumbs = [{
        title: "Home", path: "/dashboard", isSeparator: false, isActive: false,
    }, {
        title: "", path: "", isSeparator: true, isActive: false,
    },];

    const columns = [{
        dataField: "id", text: "Id", hidden: true
    }, {
        dataField: "name", text: "Device Name", formatter: HeaderColumnFormatter, search: true
    }, {
        dataField: "createdOn", text: "Created On", formatter: DMSDateColumnFormatter,
    }, {
        dataField: "updatedOn", text: "Updated On", formatter: DMSDateColumnFormatter,
    }, {
        dataField: "action", text: "Actions", formatter: ActionsColumnFormatter, formatExtraData: {
            buttons: [{
                name: "Edit", color: "primary", icon: "edit", method: openEditDialog
            }, {
                name: "Delete", color: "danger", icon: "trash-alt", method: openDeleteDialog
            }],
        }, classes: "text-right pr-0", headerClasses: "text-right pr-3", style: {
            minWidth: "100px",
        },
    },];

    function openEditDialog(row) {
        setShowModelId(row.id);
        setShowModal(true);
    }

    function reloadData() {
        setReload(reload + 1);
    }

    async function openDeleteDialog(id) {
        const result = await showConfirmDialog("Do you really want to delete the Device? You will be able to delete it only if it is not used anywhere.");
        if (result) {
            genericDelete(ENTITY_ID_DEVICES, id).then(() => {
                showToast("Device is Deleted!", TOAST_SUCCESS);
                reloadData();
            }).catch(() => {
            }).finally(() => {
                reloadData();
            });
        }
    }

    function addButtonClicked() {
        setShowModelId(null);
        setShowModal(true);
    }

    const handleClose = () => setShowModal(false);

    useEffect(() => {
        myData.setBaseData({
            toolBarButtons: [{
                name: "ADD DEVICE", color: "primary", icon: "fa fa-plus", onClick: addButtonClicked,
            },],
        });

        setPageLoadComplete(true);
    }, []);

    const BaseCard = () => (<div className="card card-body">
        {pageLoadComplete && (<>
            <GenericTable
                reload={reload}
                defaultData={defaultList}
                columns={columns}
                entityId={ENTITY_ID_DEVICES}
            />
        </>)}
    </div>);

    return (<>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Devices</PageTitle>
        <BaseCard/>
        <AddDevicesDialog show={showModal} handleClose={handleClose} id={showModelId}/>
    </>);
}
