export const VALIDATE_ONLY_SMALL_CHARS = 1;

export function restrictData(type, inputValue) {
    if (type.includes(0)) {
        return inputValue;
    }

    if (type.includes(VALIDATE_ONLY_SMALL_CHARS)) {
        // Convert the input value to lowercase
        inputValue = inputValue.toLowerCase();

        // Remove any non-alphabetic characters
        inputValue = inputValue.replace(/[^a-z]/g, '');
    }

    return inputValue;
}
