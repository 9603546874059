/* eslint-disable react/jsx-no-target-blank */
import React, {useContext} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from "./SidebarMenuItemWithSub";
import {useAuth} from "../../../../../app/modules/auth";
import {allowToShowMenu, isSuperAdmin} from "../../../../../app/utils/Utils";
import {useLocation} from 'react-router'
import OreoContext from "../../../../../app/OreoContext";

// Icons
// Font Icons - https://preview.keenthemes.com/metronic-v4/theme/admin_4/ui_icons.html
// SideBar Icons - https://preview.keenthemes.com/html/metronic/docs/icons/keenicons

const SidebarMenuMain = () => {
    const intl = useIntl();
    const {currentUser} = useAuth();
    const {pathname} = useLocation();

    return (
        <>
            {isSuperAdmin(currentUser) === false &&
            <>

                <SidebarMenuItem to='/dashboard' icon='element-11' title='Dashboard'/>
                <SidebarMenuItem to='/projects' icon='book' title='Projects' />
                <SidebarMenuItemWithSub
                    to='/crafted/pages'
                    title='Reports'
                    icon='trello'>
                    <SidebarMenuItem to='/xxxx' icon='trello' title='Payments Report'/>

                </SidebarMenuItemWithSub>

                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>MANUAL</span>
                    </div>
                </div>
                <SidebarMenuItem to='/manual-test-cases' icon='abstract-31' title='Manual Test Cases'/>
                <SidebarMenuItem to='/manual-test-runs' icon='joystick' title='Manual Test Runs'/>

                {/*/!*API Testing*!/*/}
                {/*<div className='menu-item'>*/}
                {/*    <div className='menu-content pt-8 pb-2'>*/}
                {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>API TESTING</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<SidebarMenuItem to='/api-test-cases' icon='abstract-32' title='API Test Cases'/>*/}
                {/*<SidebarMenuItem to='/API' icon='square-brackets' title='API Test Runs'/>*/}

                {/*Admin*/}
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administration</span>
                    </div>
                </div>

                <SidebarMenuItemWithSub
                    to='/crafted/pages'
                    title='Settings'
                    icon='gear'>
                    <SidebarMenuItem to='/users' icon='user' title='Users'/>
                    <SidebarMenuItem to='/tags' icon='tag' title='Tags'/>
                    <SidebarMenuItem to='/devices' icon='phone' title='Devices'/>
                </SidebarMenuItemWithSub>

                {/*<SidebarMenuItemWithSub*/}
                {/*    to='/crafted/pages'*/}
                {/*    title='Data Upload'*/}
                {/*    icon='send'>*/}
                {/*    <SidebarMenuItem to='/uploadStudents' icon='people' title='Upload Students'/>*/}
                {/*    <SidebarMenuItem to='/xxxx' icon='user-square' title='Upload Staff'/>*/}
                {/*    <SidebarMenuItem to='/xxxx' icon='graph-3' title='Upload Photos'/>*/}

                {/*</SidebarMenuItemWithSub>*/}
                <SidebarMenuItem to='/auditLogs' icon='burger-menu-3' title='Audit Logs'/>
            </>
            }

            {isSuperAdmin(currentUser) &&
            <>
                {/*Super Admin*/}
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Super Admin</span>
                    </div>
                </div>
                <SidebarMenuItem to='/sadashboard' icon='element-11' title='Dashboard'/>
                <SidebarMenuItem to='/organizations' icon='home' title='Organizations'/>
            </>
            }

            {/*Finance*/}
            {/*<div className='menu-item'>*/}
            {/*    <div className='menu-content pt-8 pb-2'>*/}
            {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Finance</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<SidebarMenuItem to='/oreoBilling' icon='bill' title='QA2.Club Billing'/>*/}


            <div className="py-5">
                &nbsp;
            </div>
        </>
    )
};

export {SidebarMenuMain}
