import React, {useContext, useEffect, useState} from "react";
import OreoContext from "../../OreoContext";
import {PageTitle} from "../../../_metronic/layout/core";
import {GenericTable} from "../generic/table/GenericTable";
import {defaultList} from "../generic/InitialStates";
import {ENTITY_ID_AUDIT_LOGS} from "../generic/Constants";
import {HeaderColumnFormatter} from "../../column-formatters/HeaderColumnFormatter";
import {DMSDateColumnFormatter} from "../../column-formatters/DMSDateColumnFormatter";

export function AuditsPage() {
    const myData = useContext(OreoContext);
    const [reload, setReload] = useState(1);
    const [pageLoadComplete, setPageLoadComplete] = useState(false);

    const profileBreadCrumbs = [
        {
            title: "Home",
            path: "/dashboard",
            isSeparator: false,
            isActive: false,
        },
        {
            title: "",
            path: "",
            isSeparator: true,
            isActive: false,
        },
    ];

    const columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: HeaderColumnFormatter
        },
        {
            dataField: "entityName",
            text: "Feature",
        },
        {
            dataField: "description",
            text: "Description",
            search: true
        },
        {
            dataField: "createdOn",
            text: "On",
            formatter: DMSDateColumnFormatter,
        },
        {
            dataField: "createdBy",
            text: "By",
        },
    ];

    useEffect(() => {
        myData.setBaseData({
            toolBarButtons: [],
        });

        setPageLoadComplete(true);
    }, []);

    const BaseCard = () => (
        <div className="card card-body">
            {pageLoadComplete && (
                <GenericTable
                    reload={reload}
                    defaultData={defaultList}
                    columns={columns}
                    entityId={ENTITY_ID_AUDIT_LOGS}
                />
            )}
        </div>
    );

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Audit Logs</PageTitle>
            <BaseCard/>
        </>
    );
}
