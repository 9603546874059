import React, {useEffect, useState} from "react";
import {cloneTestRun, closeTestRun, deleteTestRunComment, genericDelete, genericGetOne} from "../../generic/crud";
import {ENTITY_ID_MANUAL_TEST_RUN} from "../../generic/Constants";
import {
    defaultTestRun,
    getListItem,
    getRandomQuote,
    testRunStatus,
    testRunTestCaseStatus
} from "../../generic/InitialStates";
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import {useNavigate} from "react-router-dom";
import {generateColorForString, getRelativeTime, getTestProgressBarData} from "../../../utils/Utils";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../../utils/toast/Toast";
import {showConfirmDialog} from "../../../generic-components/ConfirmDialog";
import {useThemeMode} from '../../../../_metronic/partials'
import {AttachedTestCaseDetailsDialog} from "./AttachedTestCaseDetailsDialog";
import MultiProgress from "react-multi-progress";
import {AssignToUserDialog} from "./AssignToUserDialog";

export function ManualTestRunDetails({id, onTestRunClick, superReload}) {
    const [testRun, setTestRun] = useState(defaultTestRun);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const {mode} = useThemeMode();
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModelId, setShowModelId] = useState(null);
    const [reload, setReload] = useState(1);

    useEffect(() => {
        if (id != null) {
            setIsLoading(true);
            genericGetOne(ENTITY_ID_MANUAL_TEST_RUN, id).then(({data}) => {
                setTestRun(data);
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, [id, reload]);

    function reloadData() {
        setReload(reload + 1)
        superReload()
    }

    function editButtonClicked() {
        navigate(`/edit-manual-test-runs/${testRun.id}`);
    }

    function viewTestCase(testCaseID, device, status, attachedTCid, comments) {
        setShowModelId({
            runId: id, device: device, tcId: testCaseID, status: status, attachedTCid: attachedTCid, comments: comments
        });
        setShowModal1(true);
        setShowModal2(false);
    }

    function assignToUser() {
        setShowModelId(id);
        setShowModal1(false);
        setShowModal2(true);
    }

    const handleClose = () => {
        setShowModal1(false);
        setShowModal2(false);
        reloadData();
    };

    function deleteComment(commentId) {
        deleteTestRunComment(testRun.id, commentId).then(({data}) => {
            // viewData.comments = viewData?.comments?.filter(comment => comment.id !== id);
            reloadData();
        })
            .catch(() => {
            });
    }

    async function openDeleteDialog(id) {
        const result = await showConfirmDialog("Do you really want to delete the Test Run?", mode);
        if (result) {
            genericDelete(ENTITY_ID_MANUAL_TEST_RUN, id).then(() => {
                showToast("Test Run is Deleted!", TOAST_SUCCESS);
                reloadData();
                onTestRunClick(null)
            }).catch(() => {

            }).finally(() => {
                reloadData();
            });
        }
    }

    async function openCloseDialog(id) {
        const result = await showConfirmDialog("Are you sure you want to close this Test Run? The Test Run cannot be closed if there are any cases still untested. The Test Run will be automatically marked as Done once the status of all Test Cases is determined!");
        if (result) {
            closeTestRun(id).then(() => {
                showToast("Test Run is Closed", TOAST_SUCCESS);
                reloadData();
            }).catch(() => {
            }).finally(() => {
                reloadData();
            });
        }
    }

    function cloneButtonClicked() {
        cloneTestRun(id).then(({data}) => {
            navigate(`/edit-manual-test-runs/${data}`);
            showToast("Test Run Cloned", TOAST_SUCCESS);
            setIsLoading(false);
        })
            .catch(() => {
                showToast("Test Run Clone Failed", TOAST_FAILURE);
                setIsLoading(false);
            });
    }

    return (<>
        {showModal2 && <AssignToUserDialog reloadData={reloadData} show={showModal2} handleClose={handleClose}
                                           runId={showModelId}/>}

        {showModal1 &&
            <AttachedTestCaseDetailsDialog reloadData={reloadData} show={showModal1} handleClose={handleClose}
                                           viewData={showModelId}/>}
        {id && testRun.id ? (<div className="card card-flush h-lg-100" id="kt_contacts_main">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                <div className="card-title">
                    <h3>{testRun.title}</h3>
                </div>

                <Menu menuButton={<MenuButton
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary ">
                    <i className="ki-solid ki-dots-horizontal fs-2x"/>{" "}
                </MenuButton>} transition menuClassName='my-menu'>

                    <MenuItem className="flex my-menuitem px-3" onClick={editButtonClicked}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Edit</span>
                                        <i className="fa fa-pen text-primary"/>
                                    </span>
                    </MenuItem>

                    <MenuItem className="flex my-menuitem px-3" onClick={cloneButtonClicked}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Clone</span>
                                        <i className="fa fa-clone text-primary"/>
                                    </span>
                    </MenuItem>
                    <MenuItem className="flex my-menuitem px-3" onClick={assignToUser}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Assign</span>
                                        <i className="fa fa-share text-primary"/>
                                    </span>
                    </MenuItem>
                    <MenuItem className="flex my-menuitem px-3" onClick={() => openCloseDialog(id)}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Close</span>
                                        <i className="fa fa-close text-warning"/>
                                    </span>
                    </MenuItem>
                    <div className='separator text-muted py-0'></div>
                    <MenuItem className="flex my-menuitem px-3" onClick={() => openDeleteDialog(id)}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Delete</span>
                                        <i className="fa fa-trash text-danger"/>
                                    </span>
                    </MenuItem>

                </Menu>
            </div>
            <div className="card-body pt-5">
                <div className="">
                    <div className="d-flex flex-column gap-2">
                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={getListItem(testRunStatus, testRun.state).icon + ' fs-6 mx-3'}
                            style={{color: getListItem(testRunStatus, testRun.state).color}}/>
                            {getListItem(testRunStatus, testRun.state).name}
                        </span>

                            <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={'fa fa-user-circle fs-6 mx-3'}
                            style={{color: '#6da7f5'}}/>
                                Assigned to {testRun?.ownerObject?.name}
                        </span>

                            <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                            <i className={'fa fa-clock-four fs-6 mx-3'}
                               style={{color: '#a65af8'}}/>Total Estimate: {testRun.totalTimeEstimateInMins} mins
                        </span>

                        </div>


                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 w-100">
                            <div className="w-50">
                                <div className="fw-bold text-muted">Tags</div>
                                <div className="mb-3">
                                    {testRun?.tags?.map(tag => (<span key={tag.id} className="badge m-1"
                                                                      style={{backgroundColor: generateColorForString(tag.name, mode)}}>
                                            <div className="fs-8">{tag.name}</div>
                                        </span>))}
                                </div>
                            </div>

                            <div className="w-50 text-end">
                                <div className="fw-bold text-muted">Run on Devices</div>
                                <div className="mb-3">
                                    {testRun?.devices?.map(device => (<span key={device.id} className="badge m-1"
                                                                            style={{backgroundColor: generateColorForString(device.name, mode)}}>
                                            <div className="fs-8">{device.name}</div>
                                        </span>))}
                                </div>
                            </div>
                        </div>

                        <MultiProgress
                            height={25}
                            backgroundColor={mode === "dark" ? "#24243d" : "#eeecec"}
                            transitionTime={0.5}
                            elements={getTestProgressBarData(testRun.attachedTestcases)}
                        />

                        <div className="d-flex align-items-center gap-2">
                            <div className="d-flex flex-column gap-1 mt-4">
                                <div className="fw-bold text-muted">Description</div>
                                <p>{testRun.description}</p>
                            </div>
                        </div>

                    </div>
                </div>
                <ul
                    className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                            data-bs-toggle="tab"
                            href="#mtd_general"
                            aria-selected="true"
                            role="tab"
                        >
                            <i className="ki-duotone ki-home fs-4 me-1"/> General
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link text-active-primary d-flex align-items-center pb-4"
                            data-bs-toggle="tab"
                            href="#mtd_comments"
                            aria-selected="false"
                            tabIndex={-1}
                            role="tab"
                        >
                            <i className="ki-duotone ki-save-2 fs-4 me-1">
                                <span className="path1"/>
                                <span className="path2"/>
                            </i>{" "}
                            Comments
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="">
                    <div
                        className="tab-pane fade show active"
                        id="mtd_general"
                        role="tabpanel"
                    >
                        <div className="d-flex flex-column gap-5 mt-7">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th className="fw-bold text-gray-500">#</th>
                                    <th className="fw-bold text-gray-500">TITLE</th>
                                    <th className="fw-bold text-gray-500">DEVICE</th>
                                    <th className="fw-bold text-gray-500">STATUS</th>
                                </tr>
                                </thead>
                                <tbody>
                                {testRun.attachedTestcases.map((tc, index) => (
                                    <tr className="cursor-pointer" key={index}
                                        onClick={() => viewTestCase(tc.testCaseID, tc.deviceName, tc.status, tc.id, tc.comments)}>
                                        <td className="ps-2">{index + 1}</td>
                                        <td><a href="#">{tc.testCaseTitle}</a></td>
                                        <td><span className="badge m-1"
                                                  style={{backgroundColor: generateColorForString(tc.deviceName, mode)}}>
                                            <div className="fs-8">{tc.deviceName}</div>
                                        </span></td>
                                        <td className="pe-2" style={{whiteSpace: 'nowrap'}}>
                                            <i className={getListItem(testRunTestCaseStatus, tc.status).icon + ' mx-1 fs-8'}
                                               style={{color: getListItem(testRunTestCaseStatus, tc.status).color}}/>
                                            <span
                                                className="fs-8 text-uppercase fw-bold text-gray-600">{getListItem(testRunTestCaseStatus, tc.status).name}</span>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div
                        className="tab-pane fade"
                        id="mtd_comments"
                        role="tabpanel">

                        <div data-kt-inbox-message="message_wrapper">
                            {testRun.attachedTestcases.map((testCase) => (<div key={testCase.id}>
                                    {testCase?.comments && testCase.comments.length > 0 &&
                                        <div className="fw-bold text-muted mb-3">{testCase.testCaseTitle}
                                            <code>{testCase.deviceName}</code></div>}

                                    {testCase?.comments?.map((comment) => (<div className="d-flex flex-stack mb-6"
                                                                                data-kt-inbox-message="header"
                                                                                key={comment.id}
                                        >
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-35px me-4">
                                                    <img alt='User Image' src={comment?.user?.imageUrl}/>
                                                </div>

                                                <div className="pe-5">
                                                    <div className="d-flex align-items-center flex-wrap gap-1">
                                                        <a href="#"
                                                           className="fw-bold text-gray-900 text-hover-primary">
                                                            {comment?.user?.name}
                                                        </a>
                                                        <i className="ki-duotone ki-abstract-8 fs-7 text-success mx-3">
                                                            <span className="path1"/>
                                                            <span className="path2"/>
                                                        </i>{" "}
                                                        <span
                                                            className="text-muted fw-bold">{getRelativeTime(comment.createdDate)}</span>
                                                    </div>

                                                    <div
                                                        className="text-muted fw-semibold"
                                                        data-kt-inbox-message="preview"
                                                    >
                                                        {comment.comment}
                                                    </div>
                                                </div>
                                            </div>
                                            <i className="fa fa-trash float-end cursor-pointer" title={"Delete Comment"}
                                               onClick={() => deleteComment(comment.id)}/>
                                        </div>))}

                                    {testCase?.comments && testCase.comments.length > 0 &&
                                        <div className='separator separator-dashed mx-lg-5 mt-2 mb-6'></div>

                                    }
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>
        </div>) : (<div>
            <div className="card card-flush h-md-100">
                <div
                    className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
                    style={{
                        backgroundPosition: "100% 50%", backgroundImage: 'url("/media/stock/900x600/42.png")'
                    }}>
                    <div className="mb-10">

                        <div className="text-gray-600 text-center mb-13">
                                    <span className="me-2">Please click on the test runs listed in the left panel to view the test run details.
                                    </span>
                        </div>


                    </div>

                    <img
                        className="mx-auto h-150px h-lg-400px  theme-light-show"
                        src="/media/illustrations/sigma-1/12.png"
                        alt=""
                    />
                    <img
                        className="mx-auto h-150px h-lg-400px  theme-dark-show"
                        src="/media/illustrations/sigma-1/12-dark.png"
                        alt=""
                    />

                    <div className="text-gray-500 text-center mb-13">
                                    <span className="me-2 ">
                                        {getRandomQuote()}
                                    </span>
                    </div>
                </div>
            </div>
        </div>)}
    </>);
}