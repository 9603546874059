import React, {useEffect, useState} from "react";
import AsyncSelect from 'react-select/async';
import {Form} from "react-bootstrap";
import {genericSelectGetAll} from "../pages/generic/crud";
import {useThemeMode} from '../../_metronic/partials'

export function AGCSelectWithCallback({callBack, entityId, isMulti, name, toolTip = "", value,
                              id, forUpdate = false, required = true, showAll = false, showAllText = "All", reload = 99}) {
    const [dataList, setDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { mode } = useThemeMode();

    if (toolTip === "") {
        toolTip = "Select " + name;
    }

    useEffect(() => {
        genericSelectGetAll(entityId).then(({data}) => {

            let dataListWithAll = data;
            if (showAll) {
                dataListWithAll = [{id: 9999, name: showAllText}, ...data];
            }

            setDataList(dataListWithAll);
        }).catch(() => {
            setIsLoading(false);
        });
    }, [entityId, reload]);

    const loadOptions = (inputValue, callback) => {
        const filteredOptions = dataList.filter(option =>
            option.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        callback(filteredOptions);
    };

    // Define styles for dark theme
    const darkThemeStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#1e1e2d',
            borderColor: '#323248', // Border color
            '&:hover': { borderColor: '#40405d' } // Border color on hover
        }),
        option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? '#022d65' : '#27273b',
            color: '#FFFFFF',
        }),
        singleValue: (styles) => ({ ...styles, color: '#ffffff' }),
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#27273b', // Background color for selected items
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: '#ffffff', // Text color for selected items
        }),
        menu: (styles) => ({ ...styles, backgroundColor: '#343432' }),
    };



    // Define styles for light theme if needed
    const lightThemeStyles = {
        // Define your light theme styles here
    };

    // Use the appropriate theme styles
    const selectStyles = mode === 'dark' ? darkThemeStyles : lightThemeStyles;

    return (
        <>
            <Form.Label className={required ? 'required' : ''}>{name}</Form.Label>
            <AsyncSelect
                isMulti={isMulti}
                defaultOptions={dataList}
                value={value}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                inputValue={inputValue}
                onInputChange={setInputValue}
                loadOptions={loadOptions}
                isDisabled={forUpdate}
                onChange={(option) => callBack(id, option)}
                styles={selectStyles}
            />

            <Form.Text className="text-muted">{toolTip}</Form.Text>
        </>
    );
}
