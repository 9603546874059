import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Pagination} from "./pagination/Pagination";
import {NoRecordsFoundMessage, PleaseWaitMessage} from "./pagination/TablePaginationHelpers";
import {genericGetAll} from "../crud";
import {sizePerPageList} from "../InitialStates";
import {PermissionInfo} from "../../roles-permissions/PermissionInfo";
import {Formik} from "formik";

export function GenericTable({
                                 reload,
                                 defaultData,
                                 columns,
                                 entityId,
                                 idColumn = 'id',
                                 fixedFilterData = null,
                                 fixedFilterColumn = null,
                                 pageSize = 10,
                                 fullScreenSearch = false,
                                 showSearchOn = true
                             }) {
    const [dataList, setDataList] = useState(defaultData);
    const [isLoading, setIsLoading] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(pageSize);
    const [page, setPage] = useState(1);
    const [searchField, setSearchField] = useState({text: "", dataField: ""});
    const [tableFilterData, setTableFilterData] = useState({});

    useEffect(() => {
        setSearchField(getObjectWithSearchTrue());
    }, [columns]);

    useEffect(() => {

        setIsLoading(true);
        tableFilterData.page = page;
        tableFilterData.sizePerPage = sizePerPage;
        tableFilterData.currentIndex = 0;

        if (fixedFilterColumn) {
            tableFilterData.fixedFilterData = fixedFilterData;
            tableFilterData.fixedFilterColumn = fixedFilterColumn;
        }

        tableFilterData.sortBy = "updatedOn";


        genericGetAll(entityId, tableFilterData).then(({data}) => {
            setDataList(data);
            setIsLoading(false);
            setPage(1);
        })
            .catch(() => {
                setIsLoading(false);
            });
    }, [reload, tableFilterData, entityId]);

    const handleTableChange = (filterData, newState) => {

        filterData = newState.filters;

        filterData.sizePerPage = newState.sizePerPage;

        if (filterData.page === undefined) {
            filterData.page = newState.page;
        }

        if (filterData.page === undefined) {
            filterData.page = 1;
        }

        if (filterData.page === 0) {
            filterData.page = 1;
        }

        if (filterData.sizePerPage === undefined) {
            filterData.sizePerPage = newState.sizePerPage;
        }

        const currentIndex = (filterData.page - 1) * filterData.sizePerPage;

        setIsLoading(true);
        tableFilterData.page = filterData.page;
        tableFilterData.sizePerPage = filterData.sizePerPage;
        tableFilterData.currentIndex = currentIndex;

        genericGetAll(entityId, tableFilterData).then().then(({data}) => {
            setDataList(data);
            setSizePerPage(filterData.sizePerPage);
            setIsLoading(false);
            setPage(filterData.page);
        })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const paginationOptions = {
        custom: true,
        totalSize: dataList.totalCount,
        sizePerPageList: sizePerPageList,
        sizePerPage: sizePerPage,
        page: page,
    };

    function getObjectWithSearchTrue() {
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].search === true) {
                return columns[i];
            }
        }

        return null;
    }

    let searchColumnSize = "col-lg-4";
    let searchColumnPadding = "py-10 ps-9";
    let rowItemPadding = " gy-4 gs-9"
    if (fullScreenSearch) {
        searchColumnSize = "col-lg-12"
        searchColumnPadding = ""
        rowItemPadding = "";
    }

    return (<>
        <PermissionInfo entityId={entityId}/>

        {searchField && <Formik
            initialValues={{
                searchText: "",
            }}
            onSubmit={(values) => {

                tableFilterData.searchString = values.searchText;
                tableFilterData.searchField = searchField.dataField;

                setTableFilterData(tableFilterData);
                tableFilterData.page = page;
                tableFilterData.sizePerPage = sizePerPage;
                tableFilterData.currentIndex = 0;

                setIsLoading(true);

                genericGetAll(entityId, tableFilterData).then(({data}) => {
                    setDataList(data);
                    setIsLoading(false);
                    setPage(1);
                })
                    .catch(() => {
                        setIsLoading(false);
                    });
            }}>
            {({
                  values, handleSubmit, handleBlur, setFieldValue,
              }) => (<form onSubmit={handleSubmit} className={"form form-label-right " + searchColumnPadding}>
                <div className="form-group row">
                    <div className={searchColumnSize}>
                        <input
                            type="text"
                            className="form-control"
                            name="searchText"
                            placeholder="Search"
                            onBlur={handleBlur}
                            value={values.searchText}
                            onChange={(e) => {
                                setFieldValue("searchText", e.target.value);
                                handleSubmit();
                            }}
                        />
                        {showSearchOn && <small className="form-text text-muted">
                            Search on <b>{searchField.text}</b>
                        </small>}
                    </div>
                </div>
            </form>)}
        </Formik>}

        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({paginationProps, paginationTableProps}) => {
                return (<Pagination

                    isLoading={isLoading}
                    paginationProps={paginationProps}>
                    <BootstrapTable
                        bordered={false}
                        wrapperClasses={"table-responsive table align-middle table-row-bordered table-row-solid " + rowItemPadding}
                        headerClasses="fs-5 fs-8 fw-bold text-gray-400 text-uppercase"
                        bodyClasses="fw-6 fw-semibold text-gray-600 align-middle"
                        bootstrap4
                        remote
                        keyField={idColumn}
                        data={dataList.entities === null ? [] : dataList.entities}
                        columns={columns}
                        onTableChange={handleTableChange}
                        {...paginationTableProps}>
                        <PleaseWaitMessage entities={dataList.entities}/>
                        <NoRecordsFoundMessage entities={dataList.entities}/>
                    </BootstrapTable>
                </Pagination>);
            }}
        </PaginationProvider>
    </>);
}
