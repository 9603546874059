import React from "react";
import {Link} from "react-router-dom";

export function ProjectsEmptyPage({showEmpty, addButtonClicked}) {


    return (<>
        <div className="card-body py-9">
            <div className="notice d-flex bg-light-info rounded border-info border border-dashed p-6">
                <i className="ki-duotone ki-information fs-2tx text-info me-4">
                    <span className="path1"/>
                    <span className="path2"/>
                    <span className="path3"/>
                </i>
                <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-semibold">
                        <div className="fs-6 text-gray-700">
                            <p className="mb-0">
                                Please <b>Activate</b> an existing Project, <i>OR</i> <b>Create</b> a new Project. You
                                will be able to Navigate to other Pages only when a Project is Active. Billing will be Linked to your Projects. If your Organization has invited you to a
                                project, Please Activate that Project to use <b>QA2.Club</b>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showEmpty && <div>
            <div className="col-12">
                <div className="card card-flush h-md-100">
                    <div
                        className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
                        style={{
                            backgroundPosition: "100% 50%", backgroundImage: 'url("/media/stock/900x600/42.png")'
                        }}>
                        <div className="mb-10">

                            <div className="fs-2hx text-gray-800 text-center mb-13">
                              <span className="me-2">
                                No Projects Yet!
                                <br/>
                              </span>
                            </div>

                            <div className="text-gray-600 text-center mb-13 px-20">
                              <span className="me-2">
                                You dont have any projects created yet, Ask your Manager to invite to your Organization Projects or You can create a new Project and Invite others
                                <br/>
                              </span>
                            </div>

                            <div className="text-center">
                                <Link onClick={addButtonClicked}>
                                    <button className="btn btn-primary">Add new Project</button>
                                </Link>
                            </div>
                        </div>

                        <img
                            className="mx-auto h-150px h-lg-300px  theme-light-show"
                            src="/media/illustrations/sigma-1/17.png"
                            alt=""
                        />
                        <img
                            className="mx-auto h-150px h-lg-300px  theme-dark-show"
                            src="/media/illustrations/sigma-1/17-dark.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>}
    </>);
}
