import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {ENTITY_ID_MANUAL_TEST_CASE} from "../../generic/Constants";
import {defaultDevice, defaultList} from "../../generic/InitialStates";
import {GenericTable} from "../../generic/table/GenericTable";
import {useThemeMode} from "../../../../_metronic/partials";
import {AttachTestCaseFormatter} from "../../../column-formatters/AttachTestCaseFormatter";


export function AttachedTestCaseDialog(props) {
    const {reload, show, handleClose, id, onAttachTestCaseClicked, onDetachTestCaseClicked, attachedTestcases} = props;
    const [forUpdate, setForUpdate] = useState(false);
    const [objectForSubmit, setObjectForSubmit] = useState(defaultDevice);
    const [isLoading, setIsLoading] = useState(false);
    const {mode} = useThemeMode();

    const columns = [{
        dataField: "title", text: "", search: true, formatter: AttachTestCaseFormatter, formatExtraData: {
            onAttachTestCaseClicked: onAttachTestCaseClicked, onDetachTestCaseClicked: onDetachTestCaseClicked, selectedId: '', mode: mode, showAttachButton: true, attachedTestcases: attachedTestcases
        }
    }, {
        dataField: "id", hidden: true,
    }]

    return (<Modal
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
            <Modal.Title>Attach Test Cases</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay">
            {!isLoading && (<div className="form-group row">

                <GenericTable
                    reload={reload}
                    defaultData={defaultList}
                    columns={columns}
                    fullScreenSearch={true}
                    showSearchOn={false}
                    entityId={ENTITY_ID_MANUAL_TEST_CASE}
                />
            </div>)}
            {isLoading && <span>Please Wait, Fetching Information from Server</span>}
        </Modal.Body>
    </Modal>);
}
