import {PageTitle} from "../../../../_metronic/layout/core";
import React, {useContext, useEffect, useState} from "react";
import OreoContext from "../../../OreoContext";
import {AGCStaticSelect} from "../../../generic-components/AGCStaticSelect";
import {Form} from "react-bootstrap";
import {useFormik} from "formik";
import {defaultTestRun, getListItem, testRunStatus} from "../../generic/InitialStates";
import {AGCText} from "../../../generic-components/AGCText";
import {AGCTextArea} from "../../../generic-components/AGCTextArea";
import {apiGenericCreate, apiGenericUpdate, genericDelete, genericGetOne} from "../../generic/crud";
import {ENTITY_ID_DEVICES, ENTITY_ID_MANUAL_TEST_RUN, ENTITY_ID_TAGS, ENTITY_ID_USER} from "../../generic/Constants";
import {showToast, TOAST_SUCCESS} from "../../../utils/toast/Toast";
import {AGCSelect} from "../../../generic-components/AGCSelect";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../../modules/auth";
import {AddTagsDialog} from "../../tags/AddTagsDialog";
import {showConfirmDialog} from "../../../generic-components/ConfirmDialog";
import {useThemeMode} from '../../../../_metronic/partials'
import {AddDevicesDialog} from "../../devices/AddDevicesDialog";
import {AttachedTestCaseDialog} from "./AttachedTestCaseDialog";

export function NewManualTestRun(props) {
    const {type} = props;
    const {id} = useParams();
    const [forUpdate, setForUpdate] = useState(false);
    const {currentUser} = useAuth();
    const myData = useContext(OreoContext);
    const [reload, setReload] = useState(1);
    const [objectForSubmit, setObjectForSubmit] = useState(defaultTestRun);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("Test Run");
    const navigate = useNavigate();
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const {mode} = useThemeMode();
    const profileBreadCrumbs = [{
        title: 'Home', path: '/dashboard', isSeparator: false, isActive: false,
    }, {
        title: '', path: '', isSeparator: true, isActive: false,
    }, {
        title: 'List', path: '/manual-test-runs', isSeparator: false, isActive: false,
    }, {
        title: '', path: '', isSeparator: true, isActive: false,
    },];

    useEffect(() => {
        myData.setBaseData({
            toolBarButtons: [{
                name: "DELETE", color: "danger", icon: "fa fa-trash", onClick: deleteButtonClicked,
            },],
        });

    }, [reload]);


    function reloadData() {
        setReload(reload + 1);
    }

    async function deleteButtonClicked() {
        const result = await showConfirmDialog("Do you really want to delete the Test Run?", mode);
        if (result) {
            genericDelete(ENTITY_ID_MANUAL_TEST_RUN, id).then(() => {
                showToast("Test Run is Deleted!", TOAST_SUCCESS);
            }).catch(() => {

            }).finally(() => {
                navigate('/manual-test-runs');
            });
        }
    }

    function addTagsButtonClicked() {
        setShowModal1(true);
        setShowModal2(false);
        setShowModal3(false);
    }

    function addDevicesButtonClicked() {
        setShowModal1(false);
        setShowModal2(true);
        setShowModal3(false);
    }

    function attachTestCasesButtonClicked() {
        setShowModal3(true);
        setShowModal1(false);
        setShowModal2(false);
    }

    const handleClose = () => {
        setShowModal1(false);
        setShowModal2(false);
        setShowModal3(false);
        reloadData();
    };

    const validationSchema = Yup.object({
        title: Yup.string().required('Test Run Title is required'),
        description: Yup.string().required('Test Run Description is required'),
        tags: Yup.array().min(1, 'At least one tag is required').required('At least one tag is required'),
        devices: Yup.array().min(1, 'At least one device is required').required('At least one device is required'),
    });

    const handleSubmit = (testRun, actions) => {
        setIsLoading(true);

        let testRunSubmit = {...testRun};
        testRunSubmit.ownerObject = testRun.ownerObject;

        testRunSubmit.state = testRunSubmit.state.id;
        testRunSubmit.owner = testRunSubmit.owner.id;

        const onSuccess = () => {
            setIsLoading(false);
            if (forUpdate) {
                showToast("Test Run is Updated!", TOAST_SUCCESS);
            } else {
                showToast("Test Run is Created!", TOAST_SUCCESS);
            }
            removeAllAttachedTestCases();
            navigate('/manual-test-runs');

        };

        const onFailure = () => {
            setIsLoading(false);
        };

        if (!id) {
            apiGenericCreate(ENTITY_ID_MANUAL_TEST_RUN, testRunSubmit).then(onSuccess).catch(onFailure);
        } else {
            apiGenericUpdate(ENTITY_ID_MANUAL_TEST_RUN, testRunSubmit, id).then(onSuccess).catch(onFailure);
        }
    };

    useEffect(() => {
        if (id != null) {
            setForUpdate(true);
            setTitle("Edit Test Run");
            setIsLoading(false);

            genericGetOne(ENTITY_ID_MANUAL_TEST_RUN, id).then(({data}) => {
                data.state = getListItem(testRunStatus, data.state)
                data.owner = {id: data?.ownerObject?.id, name: data?.ownerObject?.name}

                setObjectForSubmit(data);
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setForUpdate(false);
            setObjectForSubmit(defaultTestRun);
            setTitle("New Test Run");
            removeAllAttachedTestCases();
        }
    }, [id]);

    useEffect(() => {
        formik.setFieldValue("owner.name", currentUser.name);
        formik.setFieldValue("owner.id", currentUser.id);
        if (!id) {
            formik.setFieldValue("title", "Test Run - " + new Date().toLocaleDateString('en-GB'));
        }
    }, [currentUser]);

    const formik = useFormik({
        initialValues: objectForSubmit, enableReinitialize: true, onSubmit: handleSubmit, validationSchema
    });

    function navigateBack() {
        removeAllAttachedTestCases();
        navigate('/manual-test-runs');
    }

    function onAttachTestCaseClicked(id, title, timeEstimateInMins) {
        if (!objectForSubmit.attachedTestcases) {
            objectForSubmit.attachedTestcases = [];
        }
        objectForSubmit.attachedTestcases.push({testCaseID: id, testCaseTitle: title, timeEstimateInMins: timeEstimateInMins});
        reloadData()
    }

    function removeAllAttachedTestCases() {
        if (!objectForSubmit.attachedTestcases) {
            return;
        }
        objectForSubmit.attachedTestcases = [];
        reloadData()
    }

    function onDetachTestCaseClicked(id) {
        if (!objectForSubmit.attachedTestcases) {
            return;
        }
        objectForSubmit.attachedTestcases = objectForSubmit.attachedTestcases.filter(tc => tc.testCaseID !== id);
        reloadData()
    }

    return <>
        <PageTitle breadcrumbs={profileBreadCrumbs}>{title}</PageTitle>
        <AddTagsDialog show={showModal1} handleClose={handleClose}/>
        <AddDevicesDialog show={showModal2} handleClose={handleClose}/>
        {objectForSubmit &&
        <AttachedTestCaseDialog reload={reload} show={showModal3} handleClose={handleClose}
                                onAttachTestCaseClicked={onAttachTestCaseClicked}
                                onDetachTestCaseClicked={onDetachTestCaseClicked}
                                attachedTestcases={objectForSubmit.attachedTestcases}/>
        }

        <div id="kt_app_content" className="app-content  flex-column-fluid ">
            <div className="app-container  container-xxl ">
                <form onSubmit={formik.handleSubmit}
                      className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework"
                     >
                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-body pt-0">
                                <Form.Group className="col-lg-12 my-4">
                                    <AGCStaticSelect
                                        data={testRunStatus}
                                        formik={formik}
                                        isMulti={false}
                                        id={"state"}
                                        name={"State"}
                                        value={formik.values.state}
                                    />
                                </Form.Group>

                                <Form.Group className="col-lg-12 my-4">
                                    <AGCSelect
                                        entityId={ENTITY_ID_USER}
                                        formik={formik}
                                        isMulti={false}
                                        id={"owner"}
                                        name={"Assigned To"}
                                        value={formik.values.owner}
                                    />
                                </Form.Group>

                            </div>
                        </div>

                        <div className="card card-flush py-4">
                            <div className="card-body pt-0">
                                <Form.Group className="col-lg-12 my-4">
                                    <AGCSelect
                                        entityId={ENTITY_ID_DEVICES}
                                        formik={formik}
                                        reload={reload}
                                        isMulti={true}
                                        id={"devices"}
                                        name={"Devices"}
                                        toolTip={"Attached Test Cases will be replicated in the Test Run based on number of devices added here"}
                                        value={formik.values.devices}
                                    />
                                    <a className="float-end cursor-pointer" onClick={addDevicesButtonClicked}><i
                                        className="fa fa-add fs-7 text-primary ms-n1 me-0 mt-3"/> ADD NEW</a>

                                </Form.Group>
                            </div>

                            <div className="card-body pt-0">
                                <Form.Group className="col-lg-12 my-4">
                                    <AGCSelect
                                        entityId={ENTITY_ID_TAGS}
                                        formik={formik}
                                        reload={reload}
                                        isMulti={true}
                                        id={"tags"}
                                        name={"Tags"}
                                        value={formik.values.tags}
                                    />
                                    <a className="float-end cursor-pointer" onClick={addTagsButtonClicked}><i
                                        className="fa fa-add fs-7 text-primary ms-n1 me-0 mt-3"/> ADD NEW</a>

                                </Form.Group>
                            </div>
                        </div>
                    </div>


                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <div className="tab-content">
                            <div
                                className="tab-pane fade show active"
                                id="kt_ecommerce_add_product_general"
                                role="tab-panel">
                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                    <div className="card card-flush py-4">
                                        <div className="card-body pt-0">

                                            <Form.Group className="col-lg-12 my-4">
                                                <AGCText formik={formik}
                                                         name={"Title"}
                                                         id={"title"}
                                                         toolTip={"Enter the Title for your test run. Try to be Precise and Short"}
                                                         value={formik.values.title}/>
                                            </Form.Group>

                                            <Form.Group className="col-lg-12 my-4">
                                                <AGCTextArea formik={formik}
                                                             name={"Description"}
                                                             id={"description"}
                                                             toolTip={"Enter the Description for your test run. This will help you to understand the test run later."}
                                                             value={formik.values.description}/>
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="card card-flush py-4">
                                        <div className="card-body pt-0">

                                            <div className="d-flex flex-stack fs-4 py-3">
                                                <div className="fs-7">
                                                    {new Set(objectForSubmit.attachedTestcases.map(tc => tc.testCaseID)).size} Test
                                                    Cases attached.
                                                </div>

                                                <span
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-original-title="Edit customer details"
                                                    data-kt-initialized={1}
                                                >
                                                    <button
                                                        onClick={attachTestCasesButtonClicked}
                                                        type="button"
                                                        className="btn btn-sm btn-flex btn-light-primary"
                                                    >
                                                      <i className="ki-duotone ki-plus-square fs-3">
                                                        <span className="path1"/>
                                                        <span className="path2"/>
                                                        <span className="path3"/>
                                                      </i>
                                                      Attach Test Case
                                                    </button>
                                                  </span>
                                            </div>
                                            <Form.Text className="text-muted">Attach Test Cases to this RUN, Test Cases
                                                in the Run will be automatically duplicated for the devices attached to
                                                this Run.</Form.Text>


                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div className="d-flex justify-content-end">
                            <a className="btn btn-light me-5" onClick={navigateBack}>
                                Cancel
                            </a>
                            <button type="submit" className="btn btn-sm btn-primary ms-4">
                                <span className="indicator-label">Save Changes</span>
                                <span className="indicator-progress">
                                    Please wait...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>


    </>
}