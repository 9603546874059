import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {AGCSelectWithCallback} from "../../../generic-components/AGCSelectWithCallback";
import {ENTITY_ID_USER} from "../../generic/Constants";
import {assignTestRunToUser} from "../../generic/crud";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../../utils/toast/Toast";


export function AssignToUserDialog(props) {
    const {show, handleClose, runId} = props;
    const [isLoading, setIsLoading] = useState(false);

    function userSelected(id, option) {
        assignTestRunToUser(runId, option.id).then(({data}) => {
            // Close the Modal
            handleClose();
            showToast("Assigned the Test Run to " + option?.name, TOAST_SUCCESS);

            setIsLoading(false);
            // reloadData();
        })
            .catch(() => {
                showToast("Assigning Test Run to " + option?.name + ", Failed", TOAST_FAILURE);
                setIsLoading(false);
            });
    }

    return (<Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
            <Modal.Title>Assign to</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay">
            {!isLoading && (<Form className="form form-label-right">
                <div className="form-group row">
                    <Form.Group className="col-lg-12">
                        <AGCSelectWithCallback
                            name={"Select User"}
                            entityId={ENTITY_ID_USER}
                            placeHolder={'Select User'}
                            id={"user"}
                            callBack={(id, option) => userSelected(id, option)}
                            required={true}
                            toolTip={"Select a User to Assign the Test Run to"}
                        />
                    </Form.Group>
                </div>
            </Form>)}
            {isLoading && <span>Please Wait, Fetching Information from Server</span>}
        </Modal.Body>
    </Modal>);
}
