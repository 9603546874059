import {getListItem, testRunTestCaseStatus} from "../pages/generic/InitialStates";

export function isEmpty(strValue) {
    return !strValue || strValue.trim() === "" || (strValue.trim()).length === 0;
}

export function trimToLength(str, limit) {
    if (str.length > limit) {
        return str.substring(0, limit) + '...';
    }
    return str;
}

export function simpleDateFormat(dateString) {
    const date = new Date(dateString);
    const options = {
        day: 'numeric', month: 'short', year: 'numeric'
    };
    return date.toLocaleDateString('en-US', options);
}

export function getRelativeTime(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
        return "Just now";
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} min${minutes > 1 ? 's' : ''} back`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} back`;
    } else if (diffInSeconds < 172800) { // 48 hours in seconds
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''} back`;
    } else {
        return `on ${getOrdinal(date.getDate())} ${date.toLocaleString('default', {month: 'short'})} ${date.getFullYear()}`;
    }
}

function getOrdinal(n) {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function getAskedPermission(entityId, permissions) {
    if (permissions === undefined || permissions === null) {
        return null;
    }

    for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].entityId) {
            for (let j = 0; j < permissions[i].entityId.length; j++) {
                if (permissions[i].entityId[j] === entityId) {
                    return permissions[i];
                }
            }
        }
    }

    return null;
}

export function allowToShowMenu(entityId, permissions) {
    let allowedPermission = getAskedPermission(entityId, permissions);
    if (allowedPermission == null) {
        return false;
    }

    return (allowedPermission.read || allowedPermission.update || allowedPermission.create || allowedPermission.delete);
}

export function isSuperAdmin(currentUser) {
    if (currentUser.orgName == null && currentUser.userId === 'amithgc') {
        return true;
    }

    return false;
}

export function getRandomColor(val1, val2, isDark) {
    if (val1 === null) {
        val1 = "";
    }

    if (val2 === null) {
        val2 = "";
    }

    // Define an array of material color codes
    const materialColors = [// Red
        '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', // Purple
        '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', // Blue
        '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', // Green
        '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', // Yellow
        // '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548',
        // Brown
        '#795548', '#9E9E9E', '#607D8B', '#333333', '#FF4081', // Pink
        '#E040FB', '#7C4DFF', '#536DFE', '#448AFF', '#40C4FF', // Cyan
        // '#18FFFF', '#64FFDA', '#69F0AE', '#B2FF59', '#EEFF41',
        // Lime
        //'#CDDC39', '#FFEB3B', '#FFC107', '#FFAB40', '#FF6E40',
        // Orange
        '#FF9800', '#FF5722', '#5D4037', '#757575', '#BDBDBD', // Grey
        '#9E9E9E', '#607D8B', '#333333', '#607D8B', '#333333',];

    // Combine val1 and val2 to create a unique seed for randomization
    const seed = val1.toLowerCase() + val2.toLowerCase();

    // Get the hash code of the seed
    let hashCode = 0;
    for (let i = 0; i < seed.length; i++) {
        hashCode = (hashCode << 5) - hashCode + seed.charCodeAt(i);
        hashCode &= hashCode; // Convert to 32-bit integer
    }

    // Generate a random index using the hash code
    const randomIndex = Math.abs(hashCode) % materialColors.length;

    // Get the random material color hex code
    const color = materialColors[randomIndex];

    // Return the color based on whether it should be dark or light
    return isDark ? color : invertColor(color);
}

// Helper function to invert the color
function invertColor(color) {
    // Remove the '#' symbol from the color code
    const hex = color.slice(1);

    // Convert the hex color to RGB format
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the inverted color
    const invertedR = 255 - r;
    const invertedG = 255 - g;
    const invertedB = 255 - b;

    // Convert the inverted color back to hex format with transparency
    // Change the alpha value (0.1) as needed
    return `rgba(${invertedR}, ${invertedG}, ${invertedB}, 0.1)`;
}

export function getStudentImage(student) {
    if (student.photo != null && student.photo.length >= 10) {
        if (student.photo.startsWith("https://agc-sl.s3.ap-south-1.amazonaws.com")) {
            return student.photo;
        }
    }

    if (student.sex === 1) {
        return "/media/sl/boy.png"
    }
    if (student.sex === 0) {
        return "/media/sl/girl.png"
    }
    return "/media/avatars/blank.png";
}

export function getNameById(id, array) {
    const selectedId = parseInt(id, 10);

    const arrayData = array.find((data) => data.id === selectedId);
    return arrayData ? arrayData.name : null;
}

export function generateColorForString(str, mode) {
    let hash = 0;
    if(str === null || str.length<=0) {
        str = "TEST";
    }

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        if (mode === 'dark') {
            value = value / 2;
        } else {
            value = (value + 255) / 2;
        }
        color += ('00' + Math.floor(value).toString(16)).substr(-2);
    }
    return color;
}

export function getTestProgressBarData(inputArrayJson, small = false) {
    const statusCounts = inputArrayJson.reduce((acc, item) => {
        acc[item.status] = (acc[item.status] || 0) + 1;
        return acc;
    }, {});

    const totalCount = inputArrayJson.length;

    const progressBarData = Object.keys(statusCounts).map(status => {
        if (!small) {
            return {
                value: parseInt(status) === 0 ? 0 : Math.round((statusCounts[status] / totalCount) * 100),
                color: getListItem(testRunTestCaseStatus, parseInt(status)).color,
                showPercentage: true,
                textColor: "white",
                fontSize: 15,
            };
        } else {
            return {
                value: parseInt(status) === 0 ? 0 : Math.round((statusCounts[status] / totalCount) * 100),
                color: getListItem(testRunTestCaseStatus, parseInt(status)).color,
            };
        }
    });

    return progressBarData;
}
