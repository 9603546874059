import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {ENTITY_ID_MANUAL_TEST_CASE} from "../../generic/Constants";
import {
    defaultComment,
    getListItem,
    testCasePriorities,
    testCaseStatus,
    testCaseType,
    testRunTestCaseStatus
} from "../../generic/InitialStates";
import {
    deleteTestRunComment,
    genericGetOne,
    updateAttachedTestCaseComments,
    updateAttachedTestCaseStatus
} from "../../generic/crud";
import {generateColorForString, getRelativeTime} from "../../../utils/Utils";
import {useThemeMode} from "../../../../_metronic/partials";
import {AGCStaticSelectWithCallback} from "../../../generic-components/AGCStaticSelectWithCallback";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../../utils/toast/Toast";
import {AGCTextArea} from "../../../generic-components/AGCTextArea";
import {useFormik} from "formik";


export function AttachedTestCaseDetailsDialog(props) {
    const {show, handleClose, viewData, reloadData} = props;
    const [testCase, setTestCase] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [tempReload, setTempReload] = useState(true);
    const {mode} = useThemeMode();
    const [objectForSubmit, setObjectForSubmit] = useState(defaultComment);

    useEffect(() => {
        setIsLoading(true);

        if (viewData.tcId != null) {
            genericGetOne(ENTITY_ID_MANUAL_TEST_CASE, viewData.tcId).then(({data}) => {
                setTestCase(data);
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, [viewData]);

    function statusChange(id, option) {
        updateAttachedTestCaseStatus(viewData.tcId, viewData.runId, viewData.attachedTCid, option.id).then(({data}) => {
            // Close the Modal
            handleClose();
            showToast("Status of the Test Case is changed to \"" + option.name + "\"", TOAST_SUCCESS);

            setIsLoading(false);
            reloadData();
        })
            .catch(() => {
                showToast("Updating Test Case Failed", TOAST_FAILURE);
                setIsLoading(false);
            });

    }

    const handleSubmit = (comment, actions) => {
        updateAttachedTestCaseComments(viewData.tcId, viewData.runId, viewData.attachedTCid, comment.comment).then(({data}) => {
            // Close the Modal
            handleClose();
            showToast("Published the comment!", TOAST_SUCCESS);

            setIsLoading(false);
            reloadData();
        })
            .catch(() => {
                showToast("Publishing Comments, Failed!!", TOAST_FAILURE);
                setIsLoading(false);
            });
    }

    const formik = useFormik({
        initialValues: objectForSubmit, enableReinitialize: true, onSubmit: handleSubmit
    });

    function deleteComment(id) {
        deleteTestRunComment(viewData.runId, id).then(({data}) => {
            viewData.comments = viewData?.comments?.filter(comment => comment.id !== id);
            setTempReload(!tempReload);
        })
            .catch(() => {
            });
    }

    return (<Modal
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
            <Modal.Title style={{marginRight: '300px'}}>{testCase?.title}</Modal.Title>
            {testCase &&
                <div className="m-0 p-0" style={{position: 'absolute', top: '0', right: '50px', width: '300px'}}>
                    <AGCStaticSelectWithCallback
                        data={testRunTestCaseStatus}
                        isMulti={false}
                        toolTip={" "}
                        required={false}
                        id={"state"}
                        name={""}
                        value={getListItem(testRunTestCaseStatus, viewData.status)}
                        callBack={(id, option) => statusChange(id, option)}
                        style={{width: '100%'}}
                    />
                </div>}
        </Modal.Header>


        <Modal.Body className="overlay">
            {testCase && (<div>
                <ul
                    className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mb-8 gap-2"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                            data-bs-toggle="tab"
                            href="#run_details_general"
                            aria-selected="true"
                            role="tab"
                        >
                            <i className="ki-duotone ki-home fs-4 me-1"/> General
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link text-active-primary d-flex align-items-center pb-4"
                            data-bs-toggle="tab"
                            href="#run_details_comments"
                            aria-selected="false"
                            tabIndex={-1}
                            role="tab"
                        >
                            <i className="ki-duotone ki-save-2 fs-4 me-1">
                                <span className="path1"/>
                                <span className="path2"/>
                            </i>{" "}
                            Comments
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="">

                    <div
                        className="tab-pane fade show active"
                        id="run_details_general"
                        role="tabpanel"
                    >
                        <div className="align-items-center">
                            <div className="d-flex flex-column gap-2">
                                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={getListItem(testCaseStatus, testCase.state).icon + ' fs-6 mx-3'}
                            style={{color: getListItem(testCaseStatus, testCase.state).color}}/>
                            {getListItem(testCaseStatus, testCase.state).name}
                        </span>
                                    <span
                                        className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={getListItem(testCasePriorities, testCase.priority).icon + ' fs-6 mx-3'}
                            style={{color: getListItem(testCasePriorities, testCase.priority).color}}/>
                                        {getListItem(testCasePriorities, testCase.priority).name}
                        </span>
                                    <span
                                        className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={getListItem(testCaseType, testCase.type).icon + ' fs-6 mx-3'}
                            style={{color: getListItem(testCaseType, testCase.type).color}}/>
                                        {getListItem(testCaseType, testCase.type).name}
                        </span>
                                    <span
                                        className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                            <i className={'fa fa-clock-four fs-6 mx-3'}
                               style={{color: '#a65af8'}}/>Estimate: {testCase.timeEstimateInMins} mins
                        </span>

                                </div>

                                <div className="mt-3">
                                    {testCase?.tags?.map(tag => (<span key={tag.id} className="badge m-1"
                                                                       style={{backgroundColor: generateColorForString(tag.name, mode)}}>
                            <div className="fs-8">{tag.name}</div>
                        </span>))}
                                </div>

                                <span className="badge m-1"
                                      style={{backgroundColor: generateColorForString(viewData.device, mode)}}>
                                            <div className="fs-5 p-4">To be tested on {viewData.device}</div>
                                        </span>

                            </div>
                        </div>

                        <div className="d-flex flex-column gap-5 mt-7">
                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Description</div>
                                <p>{testCase.description}</p>
                            </div>

                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Pre-Conditions</div>
                                <p>{testCase.precondition}</p>
                            </div>

                            {testCase?.steps?.map((step, index) => (<div
                                className="d-flex bg-light-primary rounded border-primary border border-dashed p-6"
                                key={index}>
                                <i className="ki-duotone ki-information me-4">
                                            <span
                                                className="badge badge-circle badge-outline badge-primary">{index + 1}</span>
                                </i>
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <div className="fs-6 text-gray-700">{step.step}</div>
                                        <br/>
                                        Result:
                                        <div className="fs-6 text-gray-700">{step.result}</div>
                                    </div>
                                </div>
                            </div>))}

                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="run_details_comments"
                        role="tabpanel">
                        <div data-kt-inbox-message="message_wrapper">
                            {viewData?.comments?.map((comment) => (<div
                                className="d-flex flex-stack mb-6"
                                data-kt-inbox-message="header"
                                key={comment.id}
                            >
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-35px me-4">
                                        <img alt='User Image' src={comment?.user?.imageUrl}/>
                                    </div>

                                    <div className="pe-5">
                                        <div className="d-flex align-items-center flex-wrap gap-1">
                                            <a href="#" className="fw-bold text-gray-900 text-hover-primary">
                                                {comment?.user?.name}
                                            </a>
                                            <i className="ki-duotone ki-abstract-8 fs-7 text-success mx-3">
                                                <span className="path1"/>
                                                <span className="path2"/>
                                            </i>{" "}
                                            <span
                                                className="text-muted fw-bold">{getRelativeTime(comment.createdDate)}</span>
                                        </div>

                                        <div
                                            className="text-muted fw-semibold"
                                            data-kt-inbox-message="preview"
                                        >
                                            {comment.comment}
                                        </div>
                                    </div>
                                </div>
                                <i className="fa fa-trash float-end cursor-pointer" title={"Delete Comment"}
                                   onClick={() => deleteComment(comment.id)}/>
                            </div>))}
                            <form onSubmit={formik.handleSubmit}
                                  className="form"
                            >
                                <Form.Group className="col-lg-12 my-4">
                                    <AGCTextArea formik={formik}
                                                 name={""}
                                                 placeHolder={"Why did this test case execution fail?"}
                                                 id={"comment"}
                                                 required={false}
                                                 toolTip={"You can add your comments here"}
                                                 value={formik.values.comment}/>
                                </Form.Group>

                                <div className="d-flex justify-content-end">
                                    <button type="submit" className="btn btn-sm btn-primary ms-4">
                                        <span className="indicator-label">Publish Comment</span>
                                        <span className="indicator-progress">
                                                Please wait...{" "} <span
                                            className="spinner-border spinner-border-sm align-middle ms-2"/>
                                            </span>
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>)}
            {isLoading && <span>Please Wait, Fetching Information from Server</span>}
        </Modal.Body>
    </Modal>);
}
