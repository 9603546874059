import {PageTitle} from "../../../../_metronic/layout/core";
import React, {useContext, useEffect, useState} from "react";
import OreoContext from "../../../OreoContext";
import {AGCStaticSelect} from "../../../generic-components/AGCStaticSelect";
import {Form} from "react-bootstrap";
import {useFormik} from "formik";
import {
    apiMethods,
    defaultAPI,
    getListItem,
    testCasePriorities,
    testCaseStatus,
    testCaseType
} from "../../generic/InitialStates";
import {AGCText} from "../../../generic-components/AGCText";
import {AGCTextArea} from "../../../generic-components/AGCTextArea";
import {apiGenericCreate, apiGenericUpdate, genericDelete, genericGetOne} from "../../generic/crud";
import {ENTITY_ID_MANUAL_TEST_CASE, ENTITY_ID_TAGS, ENTITY_ID_USER} from "../../generic/Constants";
import {showToast, TOAST_SUCCESS} from "../../../utils/toast/Toast";
import {AGCSelect} from "../../../generic-components/AGCSelect";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../../modules/auth";
import {AddTagsDialog} from "../../tags/AddTagsDialog";
import {showConfirmDialog} from "../../../generic-components/ConfirmDialog";
import {useThemeMode} from '../../../../_metronic/partials'

export function NewApiTestPage(props) {
    const {type} = props;
    const {id} = useParams();
    const [forUpdate, setForUpdate] = useState(false);
    const {currentUser} = useAuth();
    const myData = useContext(OreoContext);
    const [reload, setReload] = useState(1);
    const [objectForSubmit, setObjectForSubmit] = useState(defaultAPI);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const [showModal1, setShowModal1] = useState(false);
    const {mode} = useThemeMode();
    const profileBreadCrumbs = [{
        title: 'Home', path: '/dashboard', isSeparator: false, isActive: false,
    }, {
        title: '', path: '', isSeparator: true, isActive: false,
    }, {
        title: 'List', path: '/manual-test-cases', isSeparator: false, isActive: false,
    }, {
        title: '', path: '', isSeparator: true, isActive: false,
    },];

    useEffect(() => {
        myData.setBaseData({
            toolBarButtons: [{
                name: "DELETE", color: "danger", icon: "fa fa-trash", onClick: deleteButtonClicked,
            },],
        });

    }, [reload]);


    function reloadData() {
        setReload(reload + 1);
    }

    async function deleteButtonClicked() {
        const result = await showConfirmDialog("Do you really want to delete the TestCase?", mode);
        if (result) {
            genericDelete(ENTITY_ID_MANUAL_TEST_CASE, id).then(() => {
                showToast("TestCase is Deleted!", TOAST_SUCCESS);
            }).catch(() => {

            }).finally(() => {
                navigate('/manual-test-cases');
            });
        }
    }

    function addTagsButtonClicked() {
        setShowModal1(true);
    }

    const handleClose = () => {
        setShowModal1(false);
        reloadData();
    };

    const validationSchema = Yup.object({
        title: Yup.string().required('Test Case Title is required'),
        description: Yup.string().required('Test Case Description is required'),
        headers: Yup.array().of(Yup.object().shape({
            key: Yup.string().required('Header is required'), value: Yup.string().required('Header is required'),
        })),
        tags: Yup.array().min(1, 'At least one tag is required').required('At least one tag is required'),
    });

    const handleSubmit = (testCase, actions) => {
        setIsLoading(true);

        let testCaseSubmit = {...testCase};
        delete testCaseSubmit.actionType;
        testCaseSubmit.ownerObject = testCase.ownerObject;

        testCaseSubmit.state = testCaseSubmit.state.id;
        testCaseSubmit.owner = testCaseSubmit.owner.id;
        testCaseSubmit.priority = testCase.priority.id;
        testCaseSubmit.type = testCaseSubmit.type.id;

        const onSuccess = () => {
            setIsLoading(false);
            if (forUpdate) {
                showToast("Test Case is Updated!", TOAST_SUCCESS);
            } else {
                showToast("Test Case is Created!", TOAST_SUCCESS);
            }
            if (testCase.actionType === 'saveAndNew') {
                actions.resetForm();
            } else {
                navigate('/manual-test-cases');
            }
        };

        const onFailure = () => {
            setIsLoading(false);
        };

        if (!id) {
            apiGenericCreate(ENTITY_ID_MANUAL_TEST_CASE, testCaseSubmit).then(onSuccess).catch(onFailure);
        } else {
            apiGenericUpdate(ENTITY_ID_MANUAL_TEST_CASE, testCaseSubmit, id).then(onSuccess).catch(onFailure);
        }
    };

    useEffect(() => {
        if (id != null) {
            setForUpdate(true);
            setTitle("Edit TestCase");
            setIsLoading(false);

            genericGetOne(ENTITY_ID_MANUAL_TEST_CASE, id).then(({data}) => {
                data.type = getListItem(testCaseType, data.type)
                data.state = getListItem(testCaseStatus, data.state)
                data.priority = getListItem(testCasePriorities, data.priority)
                data.owner = {id: data?.ownerObject?.id, name: data?.ownerObject?.name}

                setObjectForSubmit(data);
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setForUpdate(false);
            setObjectForSubmit(defaultAPI);
            setTitle("New API");
        }
    }, [id]);

    useEffect(() => {
        formik.setFieldValue("owner.name", currentUser.name);
        formik.setFieldValue("owner.id", currentUser.id);
    }, [currentUser]);

    const formik = useFormik({
        initialValues: objectForSubmit, enableReinitialize: true, onSubmit: handleSubmit, validationSchema
    });


    function addHeader(event) {
        event.preventDefault();
        const newHeaders = [...formik.values.headers, {key: "", value: ""}];
        formik.setFieldValue('headers', newHeaders);
    }

    function navigateBack() {
        navigate('/manual-test-cases');
    }

    function removeHeader(index) {
        const newHeaders = formik.values.headers.filter((_, i) => i !== index);
        formik.setFieldValue('headers', newHeaders);
    }

    return <>
        <PageTitle breadcrumbs={profileBreadCrumbs}>{title}</PageTitle>
        <AddTagsDialog show={showModal1} handleClose={handleClose}/>

        <div id="kt_app_content" className="app-content  flex-column-fluid ">
            <div id="kt_app_content_container" className="app-container  container-xxl ">
                <form onSubmit={formik.handleSubmit}
                      id="kt_ecommerce_add_product_form"
                      className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework"
                      data-kt-redirect="/metronic8/demo1/../demo1/apps/ecommerce/catalog/products.html">
                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-body pt-0">
                                <Form.Group className="col-lg-12 my-4">
                                    <AGCStaticSelect
                                        data={testCaseStatus}
                                        formik={formik}
                                        isMulti={false}
                                        id={"state"}
                                        name={"State"}
                                        value={formik.values.state}
                                    />
                                </Form.Group>

                                <Form.Group className="col-lg-12 my-4">
                                    <AGCStaticSelect
                                        data={testCasePriorities}
                                        formik={formik}
                                        isMulti={false}
                                        id={"priority"}
                                        name={"Priority"}
                                        value={formik.values.priority}
                                    />
                                </Form.Group>

                                <Form.Group className="col-lg-12 my-4">
                                    <AGCStaticSelect
                                        data={testCaseType}
                                        formik={formik}
                                        isMulti={false}
                                        id={"type"}
                                        name={"Test Case Type"}
                                        value={formik.values.type}
                                    />
                                </Form.Group>

                                <Form.Group className="col-lg-12 my-4">
                                    <AGCSelect
                                        entityId={ENTITY_ID_USER}
                                        formik={formik}
                                        isMulti={false}
                                        id={"owner"}
                                        name={"Owner"}
                                        value={formik.values.owner}
                                    />
                                </Form.Group>

                            </div>
                        </div>

                        <div className="card card-flush py-4">
                            <div className="card-body pt-0">
                                <Form.Group className="col-lg-12 my-4">
                                    <AGCSelect
                                        entityId={ENTITY_ID_TAGS}
                                        formik={formik}
                                        reload={reload}
                                        isMulti={true}
                                        id={"tags"}
                                        name={"Tags"}
                                        value={formik.values.tags}
                                    />
                                    <a className="float-end cursor-pointer" onClick={addTagsButtonClicked}><i
                                        className="fa fa-add fs-7 text-primary ms-n1 me-0 mt-3"/> ADD NEW</a>

                                </Form.Group>
                            </div>
                        </div>
                    </div>


                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <div className="tab-content">
                            <div
                                className="tab-pane fade show active"
                                id="kt_ecommerce_add_product_general"
                                role="tab-panel">
                                <div className="d-flex flex-column gap-7 gap-lg-10">

                                    <div className="card card-flush py-4">
                                        <div className="card-body pt-0">

                                            <Form.Group className="col-lg-12 my-4">
                                                <AGCText formik={formik}
                                                         name={"Title"}
                                                         id={"title"}
                                                         toolTip={"Enter the Title for your testcase. Try to be Precise and Short"}
                                                         value={formik.values.title}/>
                                            </Form.Group>

                                            <Form.Group className="col-lg-12 my-4">
                                                <AGCTextArea formik={formik}
                                                             name={"Description"}
                                                             id={"description"}
                                                             toolTip={"Enter the Description for your testcase. This will help you to understand the testcase later."}
                                                             value={formik.values.description}/>
                                            </Form.Group>


                                        </div>
                                    </div>

                                    <div className="card card-flush py-4">
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <Form.Group className="col-md-3 my-4">
                                                    <AGCStaticSelect
                                                        data={apiMethods}
                                                        formik={formik}
                                                        isMulti={false}
                                                        id={"method"}
                                                        name={"Method"}
                                                        value={formik.values.method}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="col-md-9 my-4">
                                                    <AGCText formik={formik}
                                                             name={"URL"}
                                                             id={"url"}
                                                             toolTip={"Enter the API URL"}
                                                             value={formik.values.url}/>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card card-flush py-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h2>Headers</h2>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            {formik.values.headers.map((header, index) => (
                                                <div key={index} className="row my-5">
                                                    <Form.Group className="col-6">
                                                        <AGCText
                                                            formik={formik}
                                                            name={`Header ${index + 1}`}
                                                            id={`headers[${index}].key`}
                                                            placeHolder={"key"}
                                                            showTitleAndTip={false}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="col-6">
                                                        <Form.Group className="col-12">
                                                            <i className='float-end bi bi-trash fs-3 justify-content-end cursor-pointer'
                                                               title={"Delete Header"}
                                                               onClick={() => removeHeader(index)}></i>
                                                        </Form.Group>
                                                        <Form.Group className="col-11">
                                                            <AGCText
                                                                formik={formik}
                                                                name={"Value"}
                                                                id={`headers[${index}].value`}
                                                                placeHolder={"value"}
                                                                showTitleAndTip={false}
                                                            />
                                                        </Form.Group>
                                                    </Form.Group>
                                                </div>))}

                                            <button className="btn btn-sm btn-primary mt-10" onClick={addHeader}>
                                                <i className='bi bi-plus fs-3'></i> Add Header
                                            </button>
                                        </div>
                                    </div>

                                    <div className="card card-flush py-4">
                                        <div className="card-body pt-0">

                                            <Form.Group className="col-lg-12 my-4">
                                                <AGCTextArea formik={formik}
                                                             name={"Body"}
                                                             id={"body"}
                                                             toolTip={"Enter the body."}
                                                             value={formik.values.body}/>
                                            </Form.Group>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="d-flex justify-content-end">
                            <a className="btn btn-light " onClick={navigateBack}>
                                Cancel
                            </a>

                            <button type="submit" className="btn btn-sm btn-success ms-4">
                                <span className="indicator-label">Validate</span>
                                <span className="indicator-progress">
                                    Please wait...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                                </span>
                            </button>

                            {!forUpdate && <button type="submit" className="btn btn-sm btn-primary ms-4"
                                                   onClick={() => formik.setFieldValue('actionType', 'saveAndNew')}>
                                <span className="indicator-label">Save & Add New</span>
                                <span className="indicator-progress">
                                    Please wait...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"/></span>
                            </button>}

                            <button type="submit" className="btn btn-sm btn-primary ms-4">
                                <span className="indicator-label">Save Changes</span>
                                <span className="indicator-progress">
                                    Please wait...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
}