import React from "react";
import {useAuth} from "../../modules/auth";
import {getAskedPermission} from "../../utils/Utils";

export function PermissionInfo({entityId}) {
    const {currentUser} = useAuth();

    let allowedPermission = getAskedPermission(entityId, currentUser.permissions);

    if (allowedPermission == null) {
        // return getHtml("You do not have access to this feature, Please contact your Administrator to get access.");
    return "";
    }

    function getHtml(message) {
        return (
            <div className="card-body p-3 px-9">
                <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6">
                    <i className="ki-duotone ki-information fs-2tx text-danger me-4">
                        <span className="path1"/>
                        <span className="path2"/>
                        <span className="path3"/>
                    </i>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-semibold">
                            <div
                                className="fs-6 text-gray-700"
                                dangerouslySetInnerHTML={{__html: message}}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (
        !allowedPermission ||
        !allowedPermission.read ||
        !allowedPermission.delete ||
        !allowedPermission.update ||
        !allowedPermission.create
    ) {
        let message = "You have limited access to <strong>" + allowedPermission.permission + "</strong> page.";

        message += " You can only";
        if (allowedPermission.read) {
            message += "<strong>&nbsp;&nbsp;<u>Read</u></strong>";
        }
        if (allowedPermission.update) {
            message += "<strong>&nbsp;&nbsp;<u>Update</u></strong>";
        }
        if (allowedPermission.create) {
            message += "<strong>&nbsp;&nbsp;<u>Create</u></strong>";
        }
        if (allowedPermission.delete) {
            message += "<strong>&nbsp;&nbsp;<u>Delete</u></strong>";
        }

        return getHtml(message);
    }

    return null;
}
