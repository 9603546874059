import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from "../core/Auth";
import {getUserByToken} from "../core/_requests";

function OAuth2RedirectHandler() {
    const location = useLocation();
    const { saveAuth, setCurrentUser } = useAuth();
    const token = getUrlParameter('token', location.search);
    const error = getUrlParameter('error', location.search);

    useEffect(() => {
        async function fetchUser() {
            if (token) {
                saveAuth({ accessToken: token });

                try {
                    const response = await getUserByToken(token);
                    const user = response.data;
                    setCurrentUser(user);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    // Handle error (e.g., setting an error state, etc.)
                }
            } else if(error) {
                console.log(error)
            }
        }

        fetchUser();
    }, [token]);

    if (token) {
        return <Navigate to="/dashboard" state={{ from: location }} />;
    } else {
        return <Navigate to="/auth" state={{ from: location, error: error }} />;
    }
}

function getUrlParameter(name, search) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default OAuth2RedirectHandler;
