import React from "react";
import ReactTooltip from "react-tooltip";

export function ActionsColumnFormatter(cellContent, row, rowIndex, { buttons }) {
    return (
        <>
            <ReactTooltip place="top" type="dark" effect="solid" html={true} />

            {buttons &&
            buttons.map((button) => {
                let aClassName =
                    "btn btn-icon btn-light btn-hover-" +
                    button.color +
                    " btn-sm mx-1";

                return (
                    <a
                        data-tip={button.name}
                        key={button.name}
                        className={aClassName}
                        onClick={() => button.method(row)}>
                        <i className={`fa fa-${button.icon} text-${button.color} fs-6`} />
                    </a>
                );
            })}
        </>
    );
}

