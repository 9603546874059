import React, {useEffect, useState} from "react";
import {cloneTestCase, genericDelete, genericGetOne} from "../../generic/crud";
import {ENTITY_ID_MANUAL_TEST_CASE, ENTITY_ID_USER} from "../../generic/Constants";
import {
    defaultTestCase,
    getListItem,
    getRandomQuote,
    testCasePriorities,
    testCaseStatus,
    testCaseType
} from "../../generic/InitialStates";
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import {useNavigate} from "react-router-dom";
import {generateColorForString} from "../../../utils/Utils";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../../utils/toast/Toast";
import {showConfirmDialog} from "../../../generic-components/ConfirmDialog";
import {useThemeMode} from '../../../../_metronic/partials'

export function ManualTestCaseDetails({id, reloadData, onTestCaseClick}) {
    const [testCase, setTestCase] = useState(defaultTestCase);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const {mode} = useThemeMode();

    useEffect(() => {
        if (id != null) {
            setIsLoading(true);
            genericGetOne(ENTITY_ID_MANUAL_TEST_CASE, id).then(({data}) => {
                setTestCase(data);
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, [id]);

    function editButtonClicked() {
        navigate(`/edit-manual-test-cases/${testCase.id}`);
    }

    async function openDeleteDialog(id) {
        const result = await showConfirmDialog("Do you really want to delete the TestCase?", mode);
        if (result) {
            genericDelete(ENTITY_ID_MANUAL_TEST_CASE, id).then(() => {
                showToast("TestCase is Deleted!", TOAST_SUCCESS);
                reloadData();
                onTestCaseClick(null)
            }).catch(() => {

                }
            ).finally(() => {
                reloadData();
            });
        }
    }

    function cloneButtonClicked() {
        cloneTestCase(id).then(({data}) => {
            navigate(`/edit-manual-test-cases/${data}`);
            showToast("TestCase Cloned", TOAST_SUCCESS);
            setIsLoading(false);
        })
            .catch(() => {
                showToast("TestCase Clone Failed", TOAST_FAILURE);
                setIsLoading(false);
            });
    }

    return (<>
        {id && testCase.id ? (<div className="card card-flush h-lg-100" id="kt_contacts_main">
            {/*begin::Card header*/}
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                {/*begin::Card title*/}
                <div className="card-title">
                    <h3>{testCase.title}</h3>
                </div>

                <Menu menuButton={<MenuButton
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary ">
                    <i className="ki-solid ki-dots-horizontal fs-2x"/>{" "}
                </MenuButton>} transition menuClassName='my-menu'>

                    <MenuItem className="flex my-menuitem px-3" onClick={editButtonClicked}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Edit</span>
                                        <i className="fa fa-pen text-primary"/>
                                    </span>
                    </MenuItem>

                    <MenuItem className="flex my-menuitem px-3" onClick={cloneButtonClicked}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Clone</span>
                                        <i className="fa fa-clone text-primary"/>
                                    </span>
                    </MenuItem>
                    <div className='separator text-muted py-0'></div>
                    <MenuItem className="flex my-menuitem px-3" onClick={() => openDeleteDialog(id)}>
                                    <span
                                        className="menu-link d-flex justify-content-between align-items-center w-100 px-3">
                                        <span>Delete</span>
                                        <i className="fa fa-trash text-danger"/>
                                    </span>
                    </MenuItem>

                </Menu>
                {/*Menu*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-5">
                {/*begin::Profile*/}

                <div className="d-flex gap-7 align-items-center">
                    <div className="d-flex flex-column gap-2">
                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={getListItem(testCaseStatus, testCase.state).icon + ' fs-6 mx-3'}
                            style={{color: getListItem(testCaseStatus, testCase.state).color}}/>
                            {getListItem(testCaseStatus, testCase.state).name}
                        </span>
                            <span
                                className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={getListItem(testCasePriorities, testCase.priority).icon + ' fs-6 mx-3'}
                            style={{color: getListItem(testCasePriorities, testCase.priority).color}}/>
                                {getListItem(testCasePriorities, testCase.priority).name}
                        </span>
                            <span
                                className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <i
                            className={getListItem(testCaseType, testCase.type).icon + ' fs-6 mx-3'}
                            style={{color: getListItem(testCaseType, testCase.type).color}}/>
                                {getListItem(testCaseType, testCase.type).name}
                        </span>
                        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                            <i className={'fa fa-clock-four fs-6 mx-3'} style={{color: '#a65af8'}}/>Estimate: {testCase.timeEstimateInMins} mins
                        </span>
                        </div>

                        <div className="mt-3">
                            {testCase?.tags?.map(tag => (<span key={tag.id} className="badge m-1"
                                                               style={{backgroundColor: generateColorForString(tag.name, mode)}}>
                            <div className="fs-8">{tag.name}</div>
                        </span>))}
                        </div>

                        <div className="d-flex align-items-center gap-2">
                            <div className="d-flex flex-column gap-1 mt-4">
                                <div className="fw-bold text-muted">Description</div>
                                <p>{testCase.description}</p>
                            </div>
                        </div>

                        {/*end::Phone*/}
                    </div>
                    {/*end::Contact details*/}
                </div>
                {/*end::Profile*/}
                {/*begin:::Tabs*/}
                <ul
                    className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                    role="tablist"
                >
                    {/*begin:::Tab item*/}
                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                            data-bs-toggle="tab"
                            href="#kt_contact_view_general"
                            aria-selected="true"
                            role="tab"
                        >
                            <i className="ki-duotone ki-home fs-4 me-1"/> General
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link text-active-primary d-flex align-items-center pb-4"
                            data-bs-toggle="tab"
                            href="#kt_contact_view_activity"
                            aria-selected="false"
                            tabIndex={-1}
                            role="tab"
                        >
                            <i className="ki-duotone ki-save-2 fs-4 me-1">
                                <span className="path1"/>
                                <span className="path2"/>
                            </i>{" "}
                            Activity
                        </a>
                    </li>
                    {/*end:::Tab item*/}
                </ul>
                {/*end:::Tabs*/}
                {/*begin::Tab content*/}
                <div className="tab-content" id="">
                    <div
                        className="tab-pane fade show active"
                        id="kt_contact_view_general"
                        role="tabpanel"
                    >
                        <div className="d-flex flex-column gap-5 mt-7">
                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Pre-Conditions</div>
                                <p style={{ whiteSpace: 'pre-wrap' }}>{testCase.precondition}</p>
                            </div>

                            {testCase.steps.map((step, index) => (<div
                                className="d-flex bg-light-primary rounded border-primary border border-dashed p-6"
                                key={index}>
                                <i className="ki-duotone ki-information me-4">
                                            <span
                                                className="badge badge-circle badge-outline badge-primary">{index + 1}</span>
                                </i>
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold" style={{ whiteSpace: 'pre-wrap' }}>
                                        <div className="fs-6 text-gray-700">{step.step}</div>
                                        <br/>
                                        Result:
                                        <div className="fs-6 text-gray-700">{step.result}</div>
                                    </div>
                                </div>
                            </div>))}

                        </div>
                    </div>

                    <div
                        className="tab-pane fade"
                        id="kt_contact_view_activity"
                        role="tabpanel">

                        {/*<div className="timeline-label">*/}
                        {/*    <div className="timeline-item">*/}
                        {/*        <div className="timeline-label fw-bold text-gray-800 fs-6">*/}
                        {/*            08:42*/}
                        {/*        </div>*/}
                        {/*        <div className="timeline-badge">*/}
                        {/*            <i className="ki-duotone ki-abstract-8 text-warning fs-1">*/}
                        {/*                <span className="path1"/>*/}
                        {/*                <span className="path2"/>*/}
                        {/*            </i>{" "}*/}
                        {/*        </div>*/}

                        {/*        <div className="fw-mormal timeline-content text-muted ps-3">*/}
                        {/*            Outlines keep you honest. And keep structure*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="timeline-item">*/}
                        {/*        /!*begin::Label*!/*/}
                        {/*        <div className="timeline-label fw-bold text-gray-800 fs-6">*/}
                        {/*            10:00*/}
                        {/*        </div>*/}
                        {/*        /!*end::Label*!/*/}
                        {/*        /!*begin::Badge*!/*/}
                        {/*        <div className="timeline-badge">*/}
                        {/*            <i className="ki-duotone ki-abstract-8 text-success fs-1">*/}
                        {/*                <span className="path1"/>*/}
                        {/*                <span className="path2"/>*/}
                        {/*            </i>{" "}*/}
                        {/*        </div>*/}
                        {/*        /!*end::Badge*!/*/}
                        {/*        /!*begin::Content*!/*/}
                        {/*        <div className="timeline-content d-flex">*/}
                        {/*            <span className="fw-bold text-gray-800 ps-3">AEOL meeting</span>*/}
                        {/*        </div>*/}
                        {/*        /!*end::Content*!/*/}
                        {/*    </div>*/}
                        {/*    /!*end::Item*!/*/}
                        {/*    /!*begin::Item*!/*/}
                        {/*    <div className="timeline-item">*/}
                        {/*        /!*begin::Label*!/*/}
                        {/*        <div className="timeline-label fw-bold text-gray-800 fs-6">*/}
                        {/*            14:37*/}
                        {/*        </div>*/}
                        {/*        /!*end::Label*!/*/}
                        {/*        /!*begin::Badge*!/*/}
                        {/*        <div className="timeline-badge">*/}
                        {/*            <i className="ki-duotone ki-abstract-8 text-danger fs-1">*/}
                        {/*                <span className="path1"/>*/}
                        {/*                <span className="path2"/>*/}
                        {/*            </i>{" "}*/}
                        {/*        </div>*/}
                        {/*        /!*end::Badge*!/*/}
                        {/*        /!*begin::Desc*!/*/}
                        {/*        <div className="timeline-content fw-bold text-gray-800 ps-3">*/}
                        {/*            Make deposit*/}
                        {/*            <a href="#" className="text-primary">*/}
                        {/*                USD 700*/}
                        {/*            </a>*/}
                        {/*            . to ESL*/}
                        {/*        </div>*/}
                        {/*        /!*end::Desc*!/*/}
                        {/*    </div>*/}

                        {/*    /!*end::Item*!/*/}
                        {/*</div>*/}
                        {/*end::Timeline*/}{" "}
                    </div>
                    {/*end:::Tab pane*/}
                </div>
                {/*end::Tab content*/}
            </div>
            {/*end::Card body*/}
        </div>) : (<div>
            <div className="card card-flush h-md-100">
                <div
                    className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
                    style={{
                        backgroundPosition: "100% 50%", backgroundImage: 'url("/media/stock/900x600/42.png")'
                    }}>
                    <div className="mb-10">

                        <div className="text-gray-600 text-center mb-13">
                                    <span className="me-2">Please click on the test cases listed in the left panel to view the test case details.
                                    </span>
                        </div>


                    </div>

                    <img
                        className="mx-auto h-150px h-lg-400px  theme-light-show"
                        src="/media/illustrations/sigma-1/12.png"
                        alt=""
                    />
                    <img
                        className="mx-auto h-150px h-lg-400px  theme-dark-show"
                        src="/media/illustrations/sigma-1/12-dark.png"
                        alt=""
                    />

                    <div className="text-gray-500 text-center mb-13">
                                    <span className="me-2 ">
                                        {getRandomQuote()}
                                    </span>
                    </div>
                </div>
            </div>
        </div>)}
    </>);
}