import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {defaultPassword} from "../generic/InitialStates";
import {showToast, TOAST_SUCCESS, TOAST_WARNING} from "../../utils/toast/Toast";
import {apiPasswordUpdate} from "../generic/crud";
import {AGCPassword} from "../../generic-components/AGCPassword";

export function UserPasswordEditDialog(props) {
    const {show, handleClose, id} = props;
    const [objectForSubmit, setObjectForSubmit] = useState(defaultPassword);
    const [isLoading, setIsLoading] = useState(false);
    const entityName = "Password";

    const formik = useFormik({
        initialValues: objectForSubmit,
        enableReinitialize: true,
        onSubmit(passwordData, {resetForm}) {
            setIsLoading(true);

            if (passwordData.password !== passwordData.confirmPassword) {
                showToast("Passwords did not match, Please try again.", TOAST_WARNING);
                setIsLoading(false);
                return;
            }

            if (passwordData.password.length < 10) {
                showToast("Password must be at least 10 characters long.", TOAST_WARNING);
                setIsLoading(false);
                return;
            }

            if (!/[a-z]/.test(passwordData.password) || !/[A-Z]/.test(passwordData.password)) {
                showToast("Password must contain both uppercase and lowercase letters.", TOAST_WARNING);
                setIsLoading(false);
                return;
            }

            if (!/[0-9]/.test(passwordData.password)) {
                showToast("Password must contain at least one numeric character.", TOAST_WARNING);
                setIsLoading(false);
                return;
            }

            if (passwordData.password.length > 50) {
                showToast("Password can be a maximum of 50 characters long.", TOAST_WARNING);
                setIsLoading(false);
                return;
            }

            apiPasswordUpdate(id, passwordData).then(() => {
                setIsLoading(false);
                handleClose();
                showToast(entityName + " is Updated!", TOAST_SUCCESS);
                resetForm();
            }).catch(() => {
                setIsLoading(false);
            });

        }
    });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right">
                        <div className="form-group row">

                            <Form.Group className="col-lg-12">
                                <AGCPassword formik={formik}
                                             name={"Password"}
                                             placeHolder={'Enter New Password'}
                                             id={"password"}
                                             toolTip={"Please enter the New Password"}
                                             value={formik.values.password}/>
                            </Form.Group>
                        </div>

                        <br/>
                        <div className="form-group row">

                            <Form.Group className="col-lg-12">
                                <AGCPassword formik={formik}
                                             name={"Confirm Password"}
                                             placeHolder={'Re-Enter New Password'}
                                             id={"confirmPassword"}
                                             toolTip={"Please re enter the New Password"}
                                             value={formik.values.confirmPassword}/>
                            </Form.Group>
                        </div>

                        <div className="card-body py-5">
                            <div
                                className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                                    <span className="path1"/>
                                    <span className="path2"/>
                                    <span className="path3"/>
                                </i>
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <div className=" text-gray-700">
                                            <ul>
                                                <li>
                                                    <Form.Text className="text-muted">Password must be at least 10
                                                        characters long.</Form.Text>
                                                </li>
                                                <li>
                                                    <Form.Text className="text-muted">Password must contain both
                                                        uppercase and lowercase letters.</Form.Text>
                                                </li>
                                                <li>
                                                    <Form.Text className="text-muted">Password must contain at least one
                                                        numeric character.</Form.Text>
                                                </li>
                                                <li>
                                                    <Form.Text className="text-muted">Password can be a maximum of 50
                                                        characters long.</Form.Text>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br/>
                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-light btn-elevate"
                                    onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate">
                                    Save
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}
