import axios from "axios";
import {ENTITY_ID_PERMISSIONS} from "./Constants";
import {API_URL} from "../../modules/auth/core/_requests";

export const GENERIC_GET_ALL = "/api/generic/all";
export const GENERIC_GET_ONE = "/api/generic/one";
export const GENERIC_DELETE = "/api/generic/delete";
export const GENERIC_CREATE = "/api/generic/create";
export const GENERIC_UPDATE = "/api/generic/update";
export const GENERIC_SELECT_GET_ALL = "/api/genericSelect";
export const GET_ALL_PERMISSIONS = "/api/permissions";
export const USER = "/api/users";


export function genericGetAll(entityId, tableFilterData) {
    if (entityId === ENTITY_ID_PERMISSIONS) {
        return getAllPermissions();
    }
    return axios.get(API_URL + GENERIC_GET_ALL + "?entityId=" + entityId, {params: tableFilterData});
}

export function getAllPermissions() {
    return axios.get(API_URL + GET_ALL_PERMISSIONS);
}

export function activateProject(projectId) {
    return axios.get(API_URL + "/api/activateProject" + "?projectId=" + projectId);
}

export function genericGetOne(entityId, id) {
    return axios.get(API_URL + GENERIC_GET_ONE + "/" + id + "?entityId=" + entityId);
}

export function genericDelete(entityId, id) {
    return axios.delete(API_URL + GENERIC_DELETE + "/" + id + "?entityId=" + entityId);
}

export function genericSelectGetAll(entityId) {
    return axios.get(API_URL + GENERIC_SELECT_GET_ALL + "/all?entityId=" + entityId);
}

export function getSelectGetDynamicData(entityId, inputValue, searchField) {
    return axios.get(API_URL + GENERIC_SELECT_GET_ALL + "/dynamic?entityId=" + entityId + "&searchField=" + searchField + "&search=" + inputValue);
}

export function apiGenericCreate(entityId, data) {
    return axios.post(API_URL + GENERIC_CREATE + "?entityId=" + entityId, data);
}

export function apiGenericUpdate(entityId, data, id) {
    return axios.post(API_URL + GENERIC_UPDATE + "?entityId=" + entityId + "&id=" + id, data);
}

export function apiPasswordUpdate(id, data) {
    return axios.post(API_URL + USER + "/password?userName=" + id, data);
}

export function cloneTestCase(testCaseId) {
    return axios.get(API_URL + "/api/cloneTestCase" + "?testCaseId=" + testCaseId);
}

export function cloneTestRun(testRunId) {
    return axios.get(API_URL + "/api/cloneTestRun" + "?testRunId=" + testRunId);
}

export function updateAttachedTestCaseStatus(tcId, runId, attachedTCid, state) {
    return axios.get(API_URL + "/api/updateAttachedTestCaseStatus" + "?tcId=" + tcId + "&runId=" + runId + "&attachedTCid=" + attachedTCid + "&state=" + state);
}

export function updateAttachedTestCaseComments(tcId, runId, attachedTCid, comment) {
    return axios.get(API_URL + "/api/updateAttachedTestCaseComments" + "?tcId=" + tcId + "&runId=" + runId + "&attachedTCid=" + attachedTCid + "&comment=" + comment);
}

export function closeTestRun(runId) {
    return axios.get(API_URL + "/api/closeTestRun" + "?runId=" + runId);
}

export function assignTestRunToUser(runId, userId) {
    return axios.get(API_URL + "/api/assignTestRunToUser" + "?runId=" + runId + "&userId=" + userId);
}

export function deleteTestRunComment(runId, commentId) {
    return axios.get(API_URL + "/api/deleteTestRunComment" + "?runId=" + runId + "&commentId=" + commentId);
}

export function inviteUser(data) {
    return axios.post(API_URL + "/api/inviteUser", data);
}

export function getInvitesForCurrentUser() {
    return axios.get(API_URL + "/api/getInvitesForCurrentUser");
}

export function acceptOrDeclineInvite(accept, inviteId) {
    return axios.get(API_URL + "/api/acceptOrDeclineInvite" + "?accept=" + accept + "&inviteId=" + inviteId);
}