import React, {useContext, useEffect} from "react";
import OreoContext from "../../../../app/OreoContext";
import {PageTitleWrapper} from "./page-title";

export function OreoToolbarWrapper() {
    const myData = useContext(OreoContext);

    useEffect(() => {
    }, [myData.baseData]);

    useEffect(() => {
        document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    }, []);

    // Iterate over the toolBarButtons array and create buttons
    let buttonsMarkup = "";

    if (myData && myData.baseData && myData.baseData.toolBarButtons) {
        buttonsMarkup = myData.baseData.toolBarButtons.map((button) => {
            return (

                <a href="#" className={`btn btn-sm btn-light-${button.color} font-weight-bold`} key={button.name}
                   onClick={button.onClick}>
                    <i className={`${button.icon}`}/> {button.name}
                </a>

            );
        });
    }

    return (
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div id="kt_app_toolbar_container" className="app-container d-flex flex-stack container-fluid">
                <PageTitleWrapper/>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    {buttonsMarkup}
                </div>
            </div>
        </div>
    );
}
