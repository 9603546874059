import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {defaultInvite, roles} from "../generic/InitialStates";
import {AGCText} from "../../generic-components/AGCText";
import * as Yup from 'yup';
import {AGCStaticSelect} from "../../generic-components/AGCStaticSelect";
import {inviteUser} from "../generic/crud";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../utils/toast/Toast";


export function InviteUserDialog(props) {
    const {show, handleClose, projectId} = props;
    const [objectForSubmit, setObjectForSubmit] = useState(defaultInvite);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('Email is required'),
    });

    const formik = useFormik({
        initialValues: objectForSubmit, enableReinitialize: true, onSubmit(inviteData, {resetForm}) {
            setIsLoading(true);
            inviteData.role = inviteData.role.id;
            inviteData.projectID = projectId;

            inviteUser(inviteData).then(({data}) => {
                setIsLoading(false);
                showToast("Invite Sent Successfully!!", TOAST_SUCCESS)
            })
                .catch(() => {
                    setIsLoading(false);
                }).finally(() => {
                setIsLoading(false);
                handleClose();
            });

        }, validationSchema
    });

    return (<Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
            <Modal.Title>Invite User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay">
            {!isLoading && (<Form className="form form-label-right">
                <div className="form-group row">

                    <Form.Group className="col-lg-12">
                        <AGCText formik={formik}
                                 name={"Email ID"}
                                 placeHolder={'Email ID to invite'}
                                 id={"email"}
                                 required={true}
                                 toolTip={"Enter an email id, We will send him/her and invitation to join the project"}
                                 value={formik.values.email}/>
                    </Form.Group>
                </div>

                <div className="form-group row">

                    <Form.Group className="col-lg-12">
                        <AGCStaticSelect
                            data={roles}
                            formik={formik}
                            isMulti={false}
                            id={"role"}
                            name={"Role"}
                            value={formik.values.role}
                        />
                    </Form.Group>
                </div>

                <br/>
                <Modal.Footer>
                    <div>
                        <button
                            type="button"
                            className="btn btn-light btn-elevate"
                            onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={formik.handleSubmit}
                            className="btn btn-primary btn-elevate ms-4">
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Form>)}
            {isLoading && <span>Please Wait, Fetching Information from Server</span>}
        </Modal.Body>
    </Modal>);
}
