/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, GOOGLE_AUTH_URL, login} from '../core/_requests'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email format') // Added email format validation
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'), // Changed the error message to be more specific to email
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
});


const initialValues = {
    email: '', password: '',
};

export function Login() {
    const [loading, setLoading] = useState(false);
    const {saveAuth, setCurrentUser} = useAuth();

    const formik = useFormik({
        initialValues, validationSchema: loginSchema, onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            try {
                const {data: auth} = await login(values.email, values.password);
                saveAuth(auth);
                const {data: user} = await getUserByToken(auth.accessToken);
                setCurrentUser(user)
            } catch (error) {
                console.error(error);
                saveAuth(undefined);
                setStatus('The login details are incorrect');
                setSubmitting(false);
                setLoading(false)
            }
        },
    });

    return (<form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                <div className='text-gray-500 fw-semibold fs-6'>Software QA Management made easy</div>
            </div>
            {/* begin::Heading */}

            {formik.status && <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>}

            <div className="row g-3 mb-9">
                {/*begin::Col*/}
                <div className="col-md-6">
                    {/*begin::Google link-*/}
                    <a href={GOOGLE_AUTH_URL}
                        className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                    >
                        <img
                            alt="Logo"
                            src="/media/svg/brand-logos/google-icon.svg"
                            className="h-15px me-3"
                        />
                        Sign in with Google
                    </a>
                    {/*end::Google link-*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-md-6">
                    {/*begin::Github link-*/}
                    <a href={GOOGLE_AUTH_URL}
                       className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                    >
                        <img
                            alt="Logo"
                            src="/media/svg/brand-logos/github-1.svg"
                            className="h-15px me-3"
                        />
                        Sign in with GitHub
                    </a>
                    {/*end::Github link-*/}
                </div>
                {/*end::Col*/}

            </div>


            <div className="separator separator-content my-14">
                <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
            </div>

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>User Name</label>
                <input
                    placeholder=''
                    {...formik.getFieldProps('username')}
                    className={clsx('form-control bg-transparent', {'is-invalid': formik.touched.email && formik.errors.email}, {
                        'is-valid': formik.touched.email && !formik.errors.email,
                    })}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (<div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>)}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx('form-control bg-transparent', {
                        'is-invalid': formik.touched.password && formik.errors.password,
                    }, {
                        'is-valid': formik.touched.password && !formik.errors.password,
                    })}
                />
                {formik.touched.password && formik.errors.password && (<div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>)}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div/>

                {/* begin::Link */}
                <Link to='/auth/forgot-password' className='link-primary'>
                    Forgot Password ?
                </Link>
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (<span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>)}
                </button>
            </div>
            {/* end::Action */}

            <div className='text-gray-500 text-center fw-semibold fs-6'>
                Looking for a Software QA Management Software?{' '}
                <Link to='/auth/registration' className='link-primary'>
                    Register Now
                </Link>
            </div>
        </form>)
}
