export function TrueFalseColumnFormatter(cellContent, row, rowIndex, { data }) {
    if (!data) {
        return <></>;
    }

    const defaultValue = cellContent.toString();
    const {displayText, color } = data.find((item) => item.value === defaultValue) || {};

    return (
        <span className={`badge badge-light-${color}`}>{displayText}</span>
    );
}
