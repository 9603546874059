import {PageTitle} from "../../../_metronic/layout/core";
import React, {useContext, useEffect, useState} from "react";
import OreoContext from "../../OreoContext";
import {defaultList} from "../generic/InitialStates";
import {useNavigate} from "react-router-dom";
import {ENTITY_ID_MANUAL_TEST_RUN} from "../generic/Constants";
import {GenericTable} from "../generic/table/GenericTable";
import {TestRunFormatter} from "../../column-formatters/TestRunFormatter";
import {ManualTestRunDetails} from "./manual/ManualTestRunDetails";
import {useThemeMode} from '../../../_metronic/partials'

export function TestRunsListing({type}) {
    const myData = useContext(OreoContext);
    const [reload, setReload] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTestRunId, setSelectedTestRunId] = useState(null);
    const navigate = useNavigate();
    const {mode} = useThemeMode();

    const profileBreadCrumbs = [{
        title: 'Home', path: '/dashboard', isSeparator: false, isActive: false,
    }, {
        title: '', path: '', isSeparator: true, isActive: false,
    },];

    useEffect(() => {
        myData.setBaseData({
            toolBarButtons: [{
                name: "ADD NEW TEST RUN", color: "primary", icon: "fa fa-plus", onClick: addButtonClicked,
            },],
        });
    }, [reload]);

    function reloadData() {
        setReload(reload + 1);
    }

    function onTestRunClick(id) {
        setSelectedTestRunId(id)
    }

    function addButtonClicked() {
        navigate('/add-manual-test-runs');
    }

    const columns = [{
        dataField: "title", text: "", search: true, formatter: TestRunFormatter, formatExtraData: {
            onClick: onTestRunClick, selectedId: selectedTestRunId, mode: mode
        }
    }, {
        dataField: "id", hidden: true,
    }]

    return <>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Manual Test Runs </PageTitle>

        <div className="row g-7">
            <div className="col-5">
                <div className="card  card-body card-flush" id="kt_contacts_list">
                    <GenericTable
                        reload={reload}
                        defaultData={defaultList}
                        columns={columns}
                        fullScreenSearch={true}
                        showSearchOn={false}
                        entityId={ENTITY_ID_MANUAL_TEST_RUN}
                    />
                </div>
            </div>
            <div className="col-7">
                <ManualTestRunDetails id={selectedTestRunId} superReload={reloadData}
                                      onTestRunClick={onTestRunClick}/>
            </div>
        </div>
    </>
}