import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import {useThemeMode} from '../../_metronic/partials'

export function AGCStaticSelectWithCallback({
                                    callBack,
                                    data,
                                    isMulti,
                                    name,
                                    toolTip = '',
                                    value,
                                    id,
                                    forUpdate = false,
                                    required = true,
                                }) {
    const [dataList, setDataList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const { mode } = useThemeMode();


    // Define styles for dark theme
    // Define styles for dark theme
    const darkThemeStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#1e1e2d',
            borderColor: '#323248', // Border color
            '&:hover': { borderColor: '#40405d' } // Border color on hover
        }),
        option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? '#022d65' : '#27273b',
            color: '#FFFFFF',
        }),
        singleValue: (styles) => ({ ...styles, color: '#ffffff' }),
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#27273b', // Background color for selected items
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: '#ffffff', // Text color for selected items
        }),
        menu: (styles) => ({ ...styles, backgroundColor: '#343432' }),
    };


    // Define styles for light theme if needed
    const lightThemeStyles = {
        // Define your light theme styles here
    };

    // Use the appropriate theme styles
    const selectStyles = mode === 'dark' ? darkThemeStyles : lightThemeStyles;

    if (toolTip === '') {
        toolTip = 'Select ' + name;
    }

    if (value === null) {
        value = '';
    }

    useEffect(() => {
        setDataList(data);
    }, [data]);

    const loadOptions = (inputValue, callback) => {
        const filteredOptions = dataList.filter((option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase()),
        );

        callback(filteredOptions);
    };

    const Option = (props) => (
        <components.Option {...props}>
            <i className={props.data.icon + ' fs-6 mx-3'} style={{ color: props.data.color }} /> {props.data.name}
        </components.Option>
    );

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <i className={props.data.icon + ' fs-6 mx-3'} style={{ color: props.data.color }} /> {children}
        </components.SingleValue>
    );

    return (
        <>
            <Form.Label className={required ? 'required' : ''}>{name}</Form.Label>
            <AsyncSelect
                isMulti={isMulti}
                defaultOptions={dataList}
                value={value}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                inputValue={inputValue}
                onInputChange={setInputValue}
                loadOptions={loadOptions}
                isDisabled={forUpdate}
                onChange={(option) => callBack(id, option)}
                components={{ Option, SingleValue }}
                styles={selectStyles}
            />
            <Form.Text className="text-muted">{toolTip}</Form.Text>
        </>
    );
}
