import React from "react";
import MultiProgress from "react-multi-progress";
import {getListItem, testRunStatus} from "../pages/generic/InitialStates";
import {generateColorForString, getRelativeTime, getTestProgressBarData, trimToLength} from "../utils/Utils";

export function TestRunFormatter(cellContent, row, rowIndex, formatExtraData) {
    let backgroundColor = formatExtraData.mode === 'dark' ? "#1e1e2d" : "#ffffff";
    if (row.id === formatExtraData.selectedId) {
        backgroundColor = formatExtraData.mode === 'dark' ? "#26263b" : "#ebf1fa";
    }

    return (
        <>
            <div className="btn-active-color-primary d-flex align-items-center cursor-pointer p-3"
                 onClick={() => formatExtraData.onClick(row.id)} style={{ backgroundColor: backgroundColor }}>
                <span data-kt-element="bullet"
                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4"
                      style={{ backgroundColor: getListItem(testRunStatus, row.state).color }} />
                <div className="flex-grow-1 me-5">
                    <div className="text-gray-700 fw-semibold fs-6">{row.title}</div>
                    <div className="text-gray-500 fw-semibold fs-8">{trimToLength(row.description, 100)}</div>
                    <div className="mt-3 d-flex flex-wrap">
                        {row.tags.map(tag => (
                            <span key={tag.id} className="badge m-1"
                                  style={{ backgroundColor: generateColorForString(tag.name, formatExtraData.mode) }}>
                                <div className="fs-8">{tag.name}</div>
                            </span>
                        ))}
                    </div>

                    <div className="mt-6 d-flex flex-wrap">
                        <MultiProgress
                            height={10}
                            backgroundColor={formatExtraData.mode === "dark" ? "#24243d" : "#eeecec"}
                            transitionTime={0.5}
                            elements={getTestProgressBarData(row.attachedTestcases, true)}
                        />
                    </div>
                </div>
            </div>
            <div className="text-muted me-2 fs-8">
                <i className="fa fa-clock fs-8 me-2" />updated {getRelativeTime(row.updatedOn)}
            </div>
        </>
    );
}
