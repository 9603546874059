import React, {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {InviteUsers, ThemeModeProvider, UpgradePlan,} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import OreoContext from "../../app/OreoContext";
import {OreoToolbarWrapper} from "./components/toolbar/OreoToolbarWrapper";
import {ToastContainer} from "react-toastify";

const MasterLayout = () => {
    const location = useLocation();
    const [baseData, setBaseDataSuper] = useState({});

    useEffect(() => {
        reInitMenu()
    }, [location.key]);

    function setBaseData (data:any) {
        let dataFinal = {...baseData, ...data}
        setBaseDataSuper (dataFinal);
    }

    return (
        <PageDataProvider>
            <ThemeModeProvider>
                <OreoContext.Provider value={
                    {baseData, setBaseData}
                }>
                    <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                            {/*<ReactTooltip place="top" type="dark" effect="solid" html={true}/>*/}

                            <HeaderWrapper/>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable={false}
                                pauseOnHover
                            />
                            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                                <Sidebar/>
                                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                                    <div className='d-flex flex-column flex-column-fluid'>
                                        <OreoToolbarWrapper/>
                                        <Content>
                                            <Outlet/>
                                        </Content>
                                    </div>
                                    <FooterWrapper/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* begin:: Drawers */}
                    {/*<ActivityDrawer/>*/}
                    {/*<RightToolbar/>*/}
                    {/*<DrawerMessenger/>*/}
                    {/* end:: Drawers */}

                    {/* begin:: Modals */}
                    <InviteUsers/>
                    <UpgradePlan/>
                    {/* end:: Modals */}
                    <ScrollTop/>
                </OreoContext.Provider>
            </ThemeModeProvider>
        </PageDataProvider>
    )
};

export {MasterLayout}
