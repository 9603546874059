import React from "react";
import { Form } from "react-bootstrap";

export function AGCTextArea({
                                formik,
                                name,
                                toolTip = "",
                                id,
                                placeHolder = "",
                                value = "",
                                title,
                                rows = 5,
                                forUpdate = false,
                                required = true
                            }) {
    const fieldProps = formik.getFieldProps(id); // Bind to Formik state

    if (toolTip === "") {
        toolTip = "Enter the " + name;
    }
    if (placeHolder === "") {
        placeHolder = "Enter the " + name;
    }
    if (value === null) {
        value = ""
    }

    return (
        <>
            <Form.Label className={required ? "required" : ""}>{name}</Form.Label>
            <Form.Control
                as="textarea"
                id={id}
                placeholder={placeHolder}
                rows={rows}
                disabled={forUpdate}
                isInvalid={formik.touched[id] && !!formik.errors[id]}
                {...fieldProps}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors[id]}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">{toolTip}</Form.Text>
        </>
    );
}
