import React from "react";
import {Form} from "react-bootstrap";
import {restrictData} from "./Validations";

export function AGCText({
                            formik,
                            name,
                            toolTip = "",
                            id,
                            placeHolder = "",
                            value = "",
                            readOnly = false,
                            validation = [0],
                            required = true,
                            showTitleAndTip = true
                        }) {

    const fieldProps = formik.getFieldProps(id); // Bind to Formik state

    if (toolTip === "") {
        toolTip = "Enter the " + name;
    }

    if (placeHolder === "") {
        placeHolder = "Enter the " + name;
    }

    if (value === null) {
        value = ""
    }

    const handleChange = (event) => {
        let inputValue = event.target.value;

        inputValue = restrictData(validation, inputValue);

        event.target.value = inputValue;
        formik.setFieldValue(id, inputValue);
        formik.handleChange(event);
    };

    return (<>
            {showTitleAndTip && <Form.Label className={required ? "required" : ""}>{name}</Form.Label>}
            <Form.Control
                id={id}
                placeholder={placeHolder}
                onChange={handleChange}
                value={value}
                disabled={readOnly}
                isInvalid={formik.touched[id] && !!formik.errors[id]}
                {...fieldProps}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors[id]}
            </Form.Control.Feedback>
            {showTitleAndTip && <Form.Text className="text-muted">{toolTip}</Form.Text>}
        </>);
}
