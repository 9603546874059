import {generateColorForString, getRelativeTime, trimToLength} from "../utils/Utils";
import {getListItem, testCasePriorities} from "../pages/generic/InitialStates";
import React from "react";

export function AttachTestCaseFormatter(cellContent, row, rowIndex, formatExtraData) {
    let backgroundColor = formatExtraData.mode === 'dark' ? "#1e1e2d" : "#ffffff";
    let alreadyAttached = formatExtraData.attachedTestcases?.find(testCase => testCase.testCaseID === row.id);

    if (alreadyAttached) {
        backgroundColor = formatExtraData.mode === 'dark' ? "#26263b" : "#ebf1fa";
    }

    const handleAttachClick = () => {
        alreadyAttached = true;
        if (formatExtraData.onAttachTestCaseClicked) {
            formatExtraData.onAttachTestCaseClicked(row.id, row.title, row.timeEstimateInMins);
        }
    };

    const handleDetachClick = () => {
        alreadyAttached = false;
        if (formatExtraData.onDetachTestCaseClicked) {
            formatExtraData.onDetachTestCaseClicked(row.id);
        }
    };

    return (<>
        <div className="btn-active-color-primary d-flex align-items-center cursor-pointer p-3"
              style={{backgroundColor: backgroundColor}}>
            <span data-kt-element="bullet"
                  className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4"
                  style={{backgroundColor: getListItem(testCasePriorities, row.priority).color}}/>
            <div className="flex-grow-1 me-5">
                <div className="text-gray-700 fw-semibold fs-6">{row.title}</div>
                <div className="text-gray-500 fw-semibold fs-8">{trimToLength(row.description, 100)}</div>
                <div className="mt-3">
                    {row.tags.map(tag => (<span key={tag.id} className="badge m-1"
                                                style={{backgroundColor: generateColorForString(tag.name, formatExtraData.mode)}}>
                            <div className="fs-8">{tag.name}</div>
                        </span>))}
                </div>
            </div>
            {formatExtraData?.showAttachButton && <>
                {!alreadyAttached ? (<button
                        type="button"
                        onClick={() => handleAttachClick(row.id)}
                        className="btn btn-sm btn-flex btn-light-primary"
                    >
                        <i className="ki-duotone ki-plus-square fs-3">
                            <span className="path1"/>
                            <span className="path2"/>
                            <span className="path3"/>
                        </i>
                        Attach
                    </button>) : (<button
                        type="button"
                        onClick={() => handleDetachClick(row.id)}
                        className="btn btn-sm btn-flex btn-light-danger"
                    >
                        <i className="ki-duotone ki-trash-square fs-3">
                            <span className="path1"/>
                            <span className="path2"/>
                            <span className="path3"/>
                        </i>
                        Detach
                    </button>)}
            </>}
        </div>
        <div className="text-muted me-2 fs-8"><i
            className="fa fa-clock fs-8 me-2"/>updated {getRelativeTime(row.updatedOn)}</div>
    </>);
}
