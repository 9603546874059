import React, { useEffect, useState } from "react";
import {acceptOrDeclineInvite, getInvitesForCurrentUser} from "../generic/crud";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../utils/toast/Toast";

export function InvitesListComponent({reloadData, reload}) {
    const [invites, setInvites] = useState([]);

    useEffect(() => {
        getInvitesForCurrentUser().then(({ data }) => {
            setInvites(data);
        }).catch((error) => {
        });
    }, [reload]);

    const handleAccept = (inviteId) => {
        acceptOrDeclineInvite(true, inviteId).then(({ data }) => {
            showToast("You have joined the Project, Activate the project to Use it", TOAST_SUCCESS)
            reloadData();
        }).catch((error) => {
            reloadData();
        });
    };

    const handleDecline = (inviteId) => {
        acceptOrDeclineInvite(false, inviteId).then(({ data }) => {
            showToast("OK, We have marked the Invite as Rejected", TOAST_SUCCESS)
            reloadData();
        }).catch((error) => {
            reloadData();
        });
    };

    return (
        <>
            {invites && invites.map((invite, index) => (
                <div key={index} className="card-body py-9">
                    <div className="overflow-auto pb-5">
                        <div
                            className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                            <i className="ki-duotone ki-devices-2 fs-2tx text-primary me-4">
                                <span className="path1"/>
                                <span className="path2"/>
                                <span className="path3"/>
                            </i>
                            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                <div className="mb-3 mb-md-0 fw-semibold">
                                    <span className="fs-5 fw-bolder text-gray-700">You are invited to join</span>
                                    <span className="fs-5 w-bolder text-primary"> {invite.projectName}</span>
                                    <div className="fs-7 text-gray-700 pe-10 pt-2">
                                        {invite.projectDescription}
                                    </div>
                                </div>

                                <div className="d-flex align-items-center">
                                    <a
                                        href="#"
                                        className="m-2 btn btn-danger px-6 text-nowrap"
                                        onClick={() => handleDecline(invite.id)}
                                    >
                                        Decline
                                    </a>

                                    <a
                                        href="#"
                                        className="m-2 btn btn-primary px-6 text-nowrap"
                                        onClick={() => handleAccept(invite.id)}
                                    >
                                        Accept
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}
