import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles/delete-overlay.css'

export function showConfirmDialog(description, mode) {

    return new Promise((resolve) => {
        const options = {
            title: 'Are you sure?',
            message: description,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        // Resolve the promise with true when "Yes" is clicked
                        resolve(true);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        // Resolve the promise with false when "No" is clicked
                        resolve(false);
                    }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {
            },
            afterClose: () => {
            },
            onClickOutside: () => {
            },
            onKeypress: () => {
            },
            onKeypressEscape: () => {
            },
            overlayClassName: mode === 'dark' ? 'overlay-custom-class-name overlay-dark-mode' : 'overlay-custom-class-name',
        };

        confirmAlert(options);
    });
}


