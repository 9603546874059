import React, {useContext, useEffect, useState} from "react";
import OreoContext from "../../OreoContext";
import {PageTitle} from "../../../_metronic/layout/core";
import {GenericTable} from "../generic/table/GenericTable";
import {defaultList} from "../generic/InitialStates";
import {ActionsColumnFormatter} from "../../column-formatters/ActionsColumnFormatter";
import {HeaderColumnFormatter} from "../../column-formatters/HeaderColumnFormatter";
import {ENTITY_ID_USER} from "../generic/Constants";
import {UserPasswordEditDialog} from "./UserPasswordEditDialog";
import {showConfirmDialog} from "../../generic-components/ConfirmDialog";
import {genericDelete} from "../generic/crud";
import {showToast, TOAST_SUCCESS} from "../../utils/toast/Toast";
import {AuditHistoryDialog} from "../audit-history/AuditHistoryDialog";
import {TrueFalseColumnFormatter} from "../../column-formatters/TrueFalseColumnFormatter";
import {DMSDateColumnFormatter} from "../../column-formatters/DMSDateColumnFormatter";

export function UsersPage() {
    const myData = useContext(OreoContext);
    const [reload, setReload] = useState(1);
    const [pageLoadComplete, setPageLoadComplete] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModelId, setShowModelId] = useState(null);

    const profileBreadCrumbs = [
        {
            title: "Home",
            path: "/dashboard",
            isSeparator: false,
            isActive: false,
        },
        {
            title: "",
            path: "",
            isSeparator: true,
            isActive: false,
        },
    ];

    const columns = [
        {
            dataField: "name",
            text: "Full Name",
            search: true
        },
        {
            dataField: "id",
            text: "User Name",
            formatter: HeaderColumnFormatter,
            hidden: true
        },
        {
            dataField: "email",
            formatter: HeaderColumnFormatter,
            text: "Email ID",
        },
        {
            dataField: "roleName",
            text: "Role",
        },
        {
            dataField: "loginCount",
            text: "Login Count",
        },
        {
            dataField: "loginDate",
            text: "Last Login",
            formatter: DMSDateColumnFormatter,
        },
        {
            dataField: "provider",
            text: "Provider",
            formatter: TrueFalseColumnFormatter,
            formatExtraData: {
                data:
                    [
                        {
                            value: "google",
                            displayText: "Google",
                            color: "primary",
                        },
                        {
                            value: "local",
                            displayText: "QA2.Club",
                            color: "info",
                        },
                        {
                            value: "github",
                            displayText: "GitHub",
                            color: "warning",
                        },
                    ],
            },
        },
        {
            dataField: "action",
            text: "Actions",
            headerStyle: {
                width: '180px',
            },
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                buttons:
                    [
                        {
                            name: "Delete",
                            color: "danger",
                            icon: "trash-alt",
                            method: openDeleteDialog
                        },
                        {
                            name: "Change Password",
                            color: "info",
                            icon: "key",
                            method: openPasswordChangeDialog
                        }
                    ],
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    function reloadData() {
        setReload(reload + 1);
    }

    async function openDeleteDialog(id) {
        const result = await showConfirmDialog("Do you really want to delete the User?");
        if (result) {
            genericDelete(ENTITY_ID_USER, id).then(() => {
                showToast("User is Deleted!", TOAST_SUCCESS);
                reloadData();
            }).catch(() => {
                }
            ).finally(() => {
                reloadData();
            });
        }
    }

    function openPasswordChangeDialog(id) {
        setShowModelId(id);
        setShowModal2(true);
    }

    function handleClose() {
        setShowModal2(false);
    }

    useEffect(() => {
        myData.setBaseData({
            toolBarButtons: [],
        });

        setPageLoadComplete(true);
    }, []);

    const BaseCard = () => (
        <div className="card card-body">
            {pageLoadComplete && (
                <>
                    <GenericTable
                        reload={reload}
                        defaultData={defaultList}
                        columns={columns}
                        entityId={ENTITY_ID_USER}
                    />
                </>
            )}
        </div>
    );

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Users</PageTitle>
            <BaseCard/>
            <UserPasswordEditDialog show={showModal2} handleClose={handleClose} id={showModelId}/>
        </>
    );
}
