export let ENTITY_ID_USER = 1;
export let ENTITY_ID_PROJECT = 2;
export let ENTITY_ID_DESIGNATION = 3;
export let ENTITY_ID_TAGS = 4;
export let ENTITY_ID_AUDIT_LOGS = 5;
export let ENTITY_ID_GENERIC_SETTINGS = 6;
export let ENTITY_ID_PROJECT_USERS = 7;

export let ENTITY_ID_MANUAL_TEST_CASE = 8;
export let ENTITY_ID_API_TEST_CASE = 9;
export let ENTITY_ID_TAG_TEST_CASE_MAPPING = 10;
export let ENTITY_ID_MANUAL_TEST_RUN = 11;
export let ENTITY_ID_TAG_TEST_RUN_MAPPING = 12;
export let ENTITY_ID_DEVICES = 13;
export let ENTITY_ID_PENDING_PROJECT_INVITES = 15;




// Not in DB
export let ENTITY_ID_PERMISSIONS = 9999;
export let ENTITY_ID_ROLE = 8888; //TODO: to be removed

