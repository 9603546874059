/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'

const initialValues = {
    firstname: '', lastname: '', email: '', password: '', changepassword: '', acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('First name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    lastname: Yup.string()
        .min(2, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Last name is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    changepassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
    const [isRegistered, setIsRegistered] = useState(false);
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const formik = useFormik({
        initialValues, validationSchema: registrationSchema, onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const {data: auth} = await register(values.email, values.firstname, values.lastname, values.password)
                if (auth.success) {
                    setStatus(auth.message);
                    setSubmitting(false);
                    setIsRegistered(true); // Set registration success to true
                } else {
                    setStatus(auth.message);
                }

                setLoading(false)
            } catch (error) {

            }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    if (isRegistered) {
        // Show success message and "Login Now" button
        return (
            <div className="col-xxl-6">
                <div className="card card-flush h-md-100">
                    <div className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
                        style={{
                            backgroundPosition: "100% 50%", backgroundImage: 'url("/media/stock/900x600/42.png")'
                        }}>
                        <div className="mb-10">

                            <div className="fs-2hx text-gray-800 text-center mb-13">
                              <span className="me-2">
                                WELCOME
                                <br/>
                              </span>
                            </div>

                            <div className="text-gray-600 text-center mb-13">
                              <span className="me-2">
                                Your account is registered to QA2.Club, You may now login and be part of the Club
                                <br/>
                              </span>
                            </div>

                            <div className="text-center">
                                <Link to="/auth/login">
                                    <button className="btn btn-primary">Login Now</button>
                                </Link>
                            </div>
                        </div>

                        <img
                            className="mx-auto h-150px h-lg-200px  theme-light-show"
                            src="/media/illustrations/misc/upgrade.svg"
                            alt=""
                        />
                        <img
                            className="mx-auto h-150px h-lg-200px  theme-dark-show"
                            src="/media/illustrations/misc/upgrade-dark.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>

        );
    }

    return (<form
            className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate
            id="kt_login_signup_form"
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
                {/* end::Title */}

                <div className='text-gray-500 fw-semibold fs-6'>School Management made easy</div>
            </div>
            {/* end::Heading */}

            {/* begin::Login options */}
            <div className='row g-3 mb-9'>
                {/* begin::Col */}
                <div className='col-md-6'>
                    {/* begin::Google link */}
                    <a
                        href='#'
                        className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    >
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                            className='h-15px me-3'
                        />
                        Sign in with Google
                    </a>
                    {/* end::Google link */}
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-md-6'>
                    {/* begin::Google link */}
                    <a
                        href='#'
                        className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    >
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                            className='theme-light-show h-15px me-3'
                        />
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
                            className='theme-dark-show h-15px me-3'
                        />
                        Sign in with Apple
                    </a>
                    {/* end::Google link */}
                </div>
                {/* end::Col */}
            </div>
            {/* end::Login options */}

            <div className='separator separator-content my-14'>
                <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
            </div>

            {formik.status && (<div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>)}

            {/* begin::Form group Firstname */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>First name</label>
                <input
                    placeholder='First name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('firstname')}
                    className={clsx('form-control bg-transparent', {
                        'is-invalid': formik.touched.firstname && formik.errors.firstname,
                    }, {
                        'is-valid': formik.touched.firstname && !formik.errors.firstname,
                    })}
                />
                {formik.touched.firstname && formik.errors.firstname && (<div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.firstname}</span>
                        </div>
                    </div>)}
            </div>
            {/* end::Form group */}
            <div className='fv-row mb-8'>
                {/* begin::Form group Lastname */}
                <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                <input
                    placeholder='Last name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastname')}
                    className={clsx('form-control bg-transparent', {
                        'is-invalid': formik.touched.lastname && formik.errors.lastname,
                    }, {
                        'is-valid': formik.touched.lastname && !formik.errors.lastname,
                    })}
                />
                {formik.touched.lastname && formik.errors.lastname && (<div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.lastname}</span>
                        </div>
                    </div>)}
                {/* end::Form group */}
            </div>

            {/* begin::Form group Email */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx('form-control bg-transparent', {'is-invalid': formik.touched.email && formik.errors.email}, {
                        'is-valid': formik.touched.email && !formik.errors.email,
                    })}
                />
                {formik.touched.email && formik.errors.email && (<div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>)}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                    <div className='position-relative mb-3'>
                        <input
                            type='password'
                            placeholder='Password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx('form-control bg-transparent', {
                                'is-invalid': formik.touched.password && formik.errors.password,
                            }, {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            })}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>)}
                    </div>
                    {/* begin::Meter */}
                    <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                    >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                    {/* end::Meter */}
                </div>
                <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx('form-control bg-transparent', {
                        'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    }, {
                        'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    })}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                    </div>)}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='kt_login_toc_agree'
                        {...formik.getFieldProps('acceptTerms')}
                    />
                    <span>
            I Accept the{' '}
                        <a
                            href='#'
                            target='_blank'
                            className='ms-1 link-primary'
                        >
              Terms
            </a>
            .
          </span>
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.acceptTerms}</span>
                        </div>
                    </div>)}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (<span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>)}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-lg btn-light-primary w-100 mb-5'
                    >
                        Cancel
                    </button>
                </Link>
            </div>
            {/* end::Form group */}
        </form>)
}
