export const defaultList = {
    totalCount: 0, entities: [],
};

export const sizePerPageList = [{text: "10", value: 10}, {text: "50", value: 50}, {text: "100", value: 100},];

export const defaultUser = {
    id: "", name: "", phone: "", password: "", active: true, role: "",
};

export const defaultDesignation = {
    id: "", name: "",
};

export const defaultProject = {
    id: "", name: "", description: "", imageNumber: 1,
};

export const defaultTag = {
    id: "", name: "",
};

export const defaultDevice = {
    id: "", name: "",
};

export const defaultPassword = {
    password: "", confirmPassword: "",
};

export const defaultRole = {
    id: "", name: "", description: "", allowedPermissions: [],
};


export const testCaseStatus = [
    {id: 0, name: "Active", icon: "fa fa-check-square", color: "#6da7f5"} ,
    {id: 1, name: "Draft", icon: "fa fa-edit", color: "#c76d2e"},
    {id: 2, name: "In Review", icon: "fa fa-comment-alt", color: "#7deb34"},
    {id: 3, name: "Outdated", icon: "fa fa-clock", color: "#a9a4a4"},
    {id: 4, name: "Rejected", icon: "fa fa-trash", color: "#eb4034"},
]

export const testRunStatus = [
    {id: 0, name: "New", icon: "fa fa-edit", color: "#6da7f5"} ,
    {id: 1, name: "In Progress", icon: "fa fa-refresh", color: "#c76d2e"},
    {id: 2, name: "In Review", icon: "fa fa-comment-alt", color: "#6761f8"},
    {id: 3, name: "Rejected", icon: "fa fa-trash", color: "#eb4034"},
    {id: 4, name: "Done", icon: "fa fa-check-square", color: "#0faf0f"},
]

export const testCaseType = [
    {id: 0, name: "Other", icon: "fa fa-atom", color: "#6da7f5"} ,
    {id: 1, name: "Acceptance", icon: "fa fa-thumbs-up", color: "#c76d2e"},
    {id: 2, name: "Accessibility", icon: "fa fa-check-square", color: "#eb4f34"},
    {id: 3, name: "Compatibility", icon: "fa fa-chess-queen", color: "#94b77c"},
    {id: 4, name: "Destructive", icon: "fa fa-bomb", color: "#d92c69"},
    {id: 5, name: "Functional", icon: "fa fa-heartbeat", color: "#72d92c"},
    {id: 6, name: "Performance", icon: "fa fa-clock", color: "#966741"},
    {id: 7, name: "Regression", icon: "fa fa-retweet", color: "#376b79"},
    {id: 8, name: "Security", icon: "fa fa-lock", color: "#802cd9"},
    {id: 9, name: "Smoke & Sanity", icon: "fa fa-fire", color: "#356216"},
    {id: 10, name: "Usability", icon: "fa fa-fan", color: "#9fab32"},
]

export const testCasePriorities = [
    {id: 0, name: "Medium", icon: "fa fa-dot-circle", color: "#6da7f5"} ,
    {id: 1, name: "Critical", icon: "fa fa-sun", color: "#c76d2e"},
    {id: 2, name: "High", icon: "fa fa-arrow-up", color: "#eb4f34"},
    {id: 3, name: "Low", icon: "fa fa-arrow-down", color: "#72d92c"},
]

export const testRunTestCaseStatus = [
    {id: 0, name: "UnTested", icon: "fa fa-dot-circle", color: "#abbbd0"} ,
    {id: 1, name: "Passed", icon: "fa fa-dot-circle", color: "#41d010"} ,
    {id: 2, name: "Failed", icon: "fa fa-dot-circle", color: "#e3314d"} ,
    {id: 3, name: "ReTest", icon: "fa fa-dot-circle", color: "#eee548"} ,
    {id: 4, name: "Blocked", icon: "fa fa-dot-circle", color: "#b454fa"} ,
    {id: 5, name: "In Progress", icon: "fa fa-dot-circle", color: "#6da7f5"} ,
    {id: 6, name: "Skipped", icon: "fa fa-dot-circle", color: "#7c8ea4"} ,

]

export const roles = [
    {id: "PROJECT_ADMIN", name: "Project Admin", icon: "fa fa-user-secret", color: "#ff8888"} ,
    {id: "ADMIN", name: "Admin", icon: "fa fa-user-plus", color: "#c76d2e"} ,
    {id: "USER", name: "User", icon: "fa fa-user", color: "#64b767"} ,
]

export const apiMethods = [
    {id: 0, name: "GET", icon: "fa fa-download", color: "#4e73df"}, // Blue, download symbolizes data retrieval
    {id: 1, name: "POST", icon: "fa fa-upload", color: "#1cc88a"}, // Green, upload symbolizes sending data
    {id: 2, name: "PUT", icon: "fa fa-refresh", color: "#36b9cc"}, // Cyan, refresh symbolizes updating/replacing data
    {id: 3, name: "DELETE", icon: "fa fa-trash", color: "#e74a3b"}, // Red, trash can symbolizes deletion
    {id: 4, name: "PATCH", icon: "fa fa-puzzle-piece", color: "#f6c23e"}, // Yellow, puzzle piece symbolizes modifying part of data
    {id: 5, name: "HEAD", icon: "fa fa-info-circle", color: "#858796"}, // Grey, info circle symbolizes meta information
    {id: 6, name: "CONNECT", icon: "fa fa-link", color: "#5a5c69"}, // Dark Grey, link symbolizes connection establishment
    {id: 7, name: "OPTIONS", icon: "fa fa-cogs", color: "#4e73df"}, // Blue, cogs symbolizes requesting communication options
    {id: 8, name: "TRACE", icon: "fa fa-search", color: "#1cc88a"}  // Green, search symbolizes message loopback test
];

export const defaultAPI = {
    id: null,
    title: "",
    description: "",
    method: apiMethods[0],
    url: "",
    headers: [
        {key: "User-Agent", value: "QA2Club"},
        {key: "Accept", value: "*/*"},
        {key: "Accept-Encoding", value: "gzip, deflate, br"},
        {key: "Connection", value: "keep-alive"},
        {key: "Content-Type", value: "application/json"},
    ],
    body: "",
    state: testCaseStatus[0],
    owner: "",
    priority: testCasePriorities[0],
    type: testCaseType[0],
}

export const defaultTestCase = {
    id: null,
    title: "",
    description: "",
    precondition: "",
    files: [],
    state: testCaseStatus[0],
    owner: "",
    priority: testCasePriorities[0],
    type: testCaseType[0],
    steps: [{step: "", result: ""}],
    timeEstimateInMins: 10
}

export const defaultTestRun = {
    id: null,
    title: "",
    description: "",
    state: testRunStatus[0],
    owner: "",
    tags: [],
    devices: [],
    attachedTestcases: []
}

export const defaultComment = {
    id: null,
    comment: "",
    userId: "",
}


export function getListItem(list, id) {
    return list.find(obj => obj.id === id);
}

export const defaultInvite = {
    role: roles[2], email: "",
};

let data = [
    "I'm not lazy, I'm in energy-saving mode.",
    "Why do programmers prefer dark mode? Because light attracts bugs.",
    "QA Engineer walks into a bar. Orders a beer. Orders 0 beers. Orders 999999999 beers. Orders a lizard. Orders -1 beers. Orders a sfdeljknesv.",
    "Debugging: Being the detective in a crime movie where you are also the murderer.",
    "I don’t always test my code, but when I do, I do it in production.",
    "There are only two hard things in computer science: cache invalidation, naming things, and off-by-one errors.",
    "Algorithm: Word used by programmers when they do not want to explain what they did.",
    "Software and cathedrals are much the same – first we build them, then we pray.",
    "There are 10 types of people in the world: those who understand binary and those who don’t.",
    "How many programmers does it take to change a light bulb? None, that's a hardware issue.",
    "Why was the JavaScript developer sad? Because he didn’t know how to 'null' his feelings.",
    "I would love to change the world, but they won’t give me the source code.",
    "A user interface is like a joke. If you have to explain it, it's not that good.",
    "My code doesn’t have bugs; it just develops random features.",
    "I would tell you a UDP joke, but you might not get it.",
    "In order to understand recursion, one must first understand recursion.",
    "Why do Java developers wear glasses? Because they can't C#.",
    "The best thing about a boolean is that even if you are wrong, you are only off by a bit.",
    "An SQL query goes into a bar, walks up to two tables and asks, 'Can I join you?'",
    "When your hammer is C++, everything begins to look like a thumb.",
    "Why do programmers always mix up Christmas and Halloween? Because Oct 31 == Dec 25.",
    "A good programmer is someone who always looks both ways before crossing a one-way street.",
    "There’s an app for that, and it’s called 'Tomorrow'.",
    "Programming is like sex: one mistake and you have to support it for the rest of your life.",
    "I have a joke on programming but it only works on my computer.",
    "To err is human; to blame it on the computer is even more so.",
    "Weeks of coding can save you hours of planning.",
    "Remember: the sooner you get behind in your work, the more time you have to catch up.",
    "If brute force doesn’t solve your problems, then you aren’t using enough.",
    "Documentation is like sex; when it's good, it's very, very good, and when it's bad, it's better than nothing."
]

export function getRandomQuote() {
    return data[Math.floor(Math.random() * data.length)];

}